const YesNoButton = ({className="", onClick, textButton }) => {
    return (
        <button
            className={`bg-yellow-amnesty hover:bg-gray-amnesty border-gray-500 hover:text-white hover:border-yellow-amnesty border font-bold h-12 w-32 text-sm ring-2 ring-opacity-10 ring-black ${className}`}
            onClick={onClick}
        >
            {textButton}
        </button>
    )
}

export default YesNoButton;