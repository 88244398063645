import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { page3Path } from "../../../utils/asset";
import WheelController from "../../WheelController";
import { useMediaQuery } from "../../../utils/customHook";
import { isMobile } from "react-device-detect";
import { useSwipeable } from "react-swipeable";

const rotateArr = (arr, negative = false, addSup = false) => {
  if (negative) {
    if (addSup) {
      return arr.slice(1).concat([arr.slice(1)[0]]); //immutable
    }
    return arr.slice(1).concat([arr[0]]); //immutable
  }
  if (addSup) {
    return [arr.slice(0, -1)[arr.slice(0, -1).length - 1]].concat(
      arr.slice(0, -1)
    ); //immutable
  }
  return [arr[arr.length - 1]].concat(arr.slice(0, -1)); //immutable
};

const Page3 = () => {
  const dispatch = useDispatch();
  const [chosenGroup, setChosenGroup] = useState(1);

  const isShort = useMediaQuery('(max-height: 660px)');

  const [slideCol, setSlideCol] = useState([[], [], [], []]);

  const [slideCount, setSlideCount] = useState(0);

  const [shuffledMobileSlide, setShufftMo] = useState([])

  useEffect(() => {
    const fisherYatesShuffle = (arr) => {
      let new_arr = [...arr];
      const len = new_arr.length;
      for (let i = len - 1; i >= 0; i--) {
        const rand = Math.floor(Math.random() * i + 1);
        [new_arr[i], new_arr[rand]] = [new_arr[rand], new_arr[i]];
        // a[]
      }
      return new_arr;
    };
    const shuffledArr = fisherYatesShuffle(page3Path.pic);
    setShufftMo(shuffledArr)
    const firstCol = shuffledArr.slice(0, 5);
    const secondCol = shuffledArr.slice(5, 10);
    const thirdCol = [shuffledArr[13]].concat(shuffledArr.slice(10, 14));
    const fourthCol = shuffledArr.slice(14, 18).concat([shuffledArr[14]]);
    setSlideCol([firstCol, secondCol, thirdCol, fourthCol]);
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      setSlideCount(slideCount + 1);
      setSlideCol([
        rotateArr(slideCol[0]),
        rotateArr(slideCol[1], true),
        rotateArr(slideCol[2], false, true),
        rotateArr(slideCol[3], true, true),
      ]);
    }, 3000);
    return () => {
      clearInterval(timerId);
    };
  }, [slideCol]);
  const groupDetail = [
    {
      numberOfChild: "5000",
      desc: "under the age of 18 have joined protests across Bangkok. This number only represents children in protest up until March 2021.\n",
    },
    {
      numberOfChild: "0134",
      desc: "have been arrested and charged with political-related allegations by the police.\n\nThe situation has been escalating throughout the year until August 2021. 8 children were charged with the lèse- majesté law with the possibility of up to 15 years imprisonment.",
    },
    {
      numberOfChild: "0045",
      desc: "were injured within just one week of the protest in August 2021. Some of them were released with bruises on their bodies. Some were attacked with rubber bullets, and one child recently passed away after getting shot from live ammunition and been in a coma for two months.",
    },
  ];

  const firstSlide = slideCol[0].slice(0, 5).map((pic, index) => {
    let animatey;
    if (index === 0) {
      animatey = -10;
    } else if (index === 4) {
      animatey = 20;
    } else {
      animatey = 0;
    }
    return (
      <motion.div
        key={`render_${pic.path}_${index}`}
        style={{ height: "16.5vw" }}
        initial={{ y: "-17.6vw" }}
        animate={{ y: animatey }}
        transition={{ type: "tween", duration: 1.5 }}
        className={`transition-colors duration-300 ${chosenGroup === pic.group ? "bg-yellow-amnesty" : "bg-gray-amnesty"
          } p-2 flex flex-col justify-between`}
      >
        <div className="sm:w-2 sm:h-2 md:w-4 md:h-4 lg:w-6 lg:h-6 xl:w-8 xl:h-8 rounded-full bg-white"></div>
        <div
          className="w-full h-4/5 bg-cover bg-center bg-no-repeat overflow-hidden relative"
          style={{ backgroundImage: `url(${pic.path})` }}
        >
          {pic.credit ? <p className="text-white absolute right-0 top-0 font-bold text-sm">{pic.credit}</p> : null}
        </div>
      </motion.div>
    );
  });

  const secondSlide = slideCol[1].slice(0, 5).map((pic, index) => {
    let animatey;
    if (index === 0) {
      animatey = -10;
    } else if (index === 4) {
      animatey = 20;
    } else {
      animatey = 0;
    }
    return (
      <motion.div
        key={`render_${pic.path}_${index}`}
        style={{ height: "16.5vw" }}
        initial={{ y: "17.6vw" }}
        animate={{ y: animatey }}
        transition={{ type: "tween", duration: 1.5 }}
        className={`transition-colors duration-300 ${chosenGroup === pic.group ? "bg-yellow-amnesty" : "bg-gray-amnesty"
          } p-2 flex flex-col justify-between`}
      >
        <div className="sm:w-2 sm:h-2 md:w-4 md:h-4 lg:w-6 lg:h-6 xl:w-8 xl:h-8 rounded-full bg-white"></div>
        <div
          className="w-full h-4/5 bg-cover bg-center bg-no-repeat overflow-hidden relative"
          style={{ backgroundImage: `url(${pic.path})` }}
        >
          {pic.credit ? <p className="text-white absolute right-0 top-0 font-bold text-sm">{pic.credit}</p> : null}
        </div>
      </motion.div>
    );
  });

  const thirdSlide = slideCol[2].slice(0, 5).map((pic, index) => {
    let animatey;
    if (index === 0) {
      animatey = -10;
    } else if (index === 4) {
      animatey = 20;
    } else {
      animatey = 0;
    }
    return (
      <motion.div
        key={`render_${pic.path}_${index}`}
        style={{ height: "16.5vw" }}
        initial={{ y: "-17.6vw" }}
        animate={{ y: animatey }}
        transition={{ type: "tween", duration: 1.5 }}
        className={`transition-colors duration-300 ${chosenGroup === pic.group ? "bg-yellow-amnesty" : "bg-gray-amnesty"
          } p-2 flex flex-col justify-between`}
      >
        <div className="sm:w-2 sm:h-2 md:w-4 md:h-4 lg:w-6 lg:h-6 xl:w-8 xl:h-8 rounded-full bg-white"></div>
        <div
          className="w-full h-4/5 bg-cover bg-center bg-no-repeat overflow-hidden relative"
          style={{ backgroundImage: `url(${pic.path})` }}
        >
          {pic.credit ? <p className="text-white absolute right-0 top-0 font-bold text-sm">{pic.credit}</p> : null}
        </div>
      </motion.div>
    );
  });

  const fourthSlide = slideCol[3].slice(0, 5).map((pic, index) => {
    let animatey;
    if (index === 0) {
      animatey = -10;
    } else if (index === 4) {
      animatey = 50;
    } else {
      animatey = 0;
    }
    return (
      <motion.div
        key={`render_${pic.path}_${index}`}
        style={{ height: "16.5vw" }}
        initial={{ y: "17.6vw" }}
        animate={{ y: animatey }}
        transition={{ type: "tween", duration: 1.5 }}
        className={`transition-colors duration-300 ${chosenGroup === pic.group ? "bg-yellow-amnesty" : "bg-gray-amnesty"
          } p-2 flex flex-col justify-between`}
      >
        <div className="sm:w-2 sm:h-2 md:w-4 md:h-4 lg:w-6 lg:h-6 xl:w-8 xl:h-8 rounded-full bg-white"></div>
        <div
          className="w-full h-4/5 bg-cover bg-center bg-no-repeat overflow-hidden relative"
          style={{ backgroundImage: `url(${pic.path})` }}
        >
          {pic.credit ? <p className="text-white absolute right-0 top-0 font-bold text-sm">{pic.credit}</p> : null}
        </div>
      </motion.div>
    );
  });

  const mobileSlide = shuffledMobileSlide.map((pic, index) => {
    return (
      <div className={`h-full ${chosenGroup === pic.group ? "bg-yellow-amnesty" : "bg-gray-amnesty"} p-2 mx-1`}>
        <div className="rounded-full bg-white w-4 h-4"></div>
        <div
          className="h-7/8 bg-cover bg-center bg-no-repeat overflow-hidden relative mt-1"
          style={{ backgroundImage: `url(${pic.path})`, width: '30vw' }}
        >
          {pic.credit ? <p className="text-white absolute right-0 top-0 font-bold text-sm">{pic.credit}</p> : null}
        </div>
      </div>
    )
  })


  const cancelhandlers = useSwipeable({
    onSwipedUp: (e)=>e.event.cancelBubble = true,
    onSwipedDown: (e)=>e.event.cancelBubble = true
  });

  return (
    <WheelController
      // customTimeWheelEnable={3000}
      onWheelFuncDown={() => {
        dispatch({ type: "SET_PAGE_ACTION", payload: { page: 4 } })
      }

      }
      onWheelFuncUp={() => {
        dispatch({ type: "SET_PAGE_ACTION", payload: { page: 2 } })
      }}
      style={{ backgroundImage: `url(${page3Path.bg})` }}
      className="flex h-screen bg-cover bg-center bg-no-repeat justify-center w-full"
    >
      <div className="hidden sm:flex items-center w-annop-w-45">
        <div className="w-1/8">
        </div>
        <div className={`flex flex-col ${isShort ? "justify-end mb-5" : "justify-center"} w-7/8 h-full`}>
          <div>
            <h1 className="inline pr-2 lg:pl-2 lg:pr-3 text-xl leading-snug md:text-4xl md:leading-snug lg:text-5xl lg:leading-snug text-white bg-black font-bold 2xl:text-7xl 2xl:leading-snug">
              WHAT’S
              <br />
            </h1>
            <h1 className="inline pr-2 lg:pl-2 lg:pr-3 text-xl leading-snug md:text-4xl md:leading-snug lg:text-5xl lg:leading-snug text-white bg-black font-bold 2xl:text-7xl 2xl:leading-snug">
              HAPPENING
              <br />
            </h1>
            <h1 className="inline pr-2 lg:pl-2 lg:pr-3 text-xl leading-snug md:text-4xl md:leading-snug lg:text-5xl lg:leading-snug text-white bg-black font-bold 2xl:text-7xl 2xl:leading-snug">
              IN THAILAND?
              <br />
            </h1>
          </div>
          <div>
            <p className="text-white font-bold mt-5">
              <span className="bg-black px-2">
                Here are stories happening in Thailand
              </span>
              <br />
              <span className="bg-black px-2">
                that you may not know.
              </span>
            </p>
          </div>
          <div className="my-10">
            <h2 className="inline text-white text-bold bg-black px-1 font-bold">
              {/* Children are */}
            </h2>
            <div className="flex flex-col lg:flex-row border-yellow-amnesty border divide-y divide-yellow-amnesty lg:divide-y-0 lg:divide-x w-10/12">
              <button
                className={`transition-colors duration-150 font-bold w-full lg:w-1/3 text-xs md:text-sm 2xl:text-base py-1.5 ${chosenGroup === 1
                  ? "bg-yellow-amnesty text-gray-amnesty"
                  : "bg-gray-amnesty text-yellow-amnesty hover:bg-yellow-amnesty hover:text-gray-amnesty"
                  } `}
                onClick={() => setChosenGroup(1)}
              >
                Joined the protest
              </button>
              <button
                className={`transition-colors duration-150 font-bold w-full lg:w-1/3 text-xs md:text-sm 2xl:text-base py-1.5 ${chosenGroup === 2
                  ? "bg-yellow-amnesty text-gray-amnesty"
                  : "bg-gray-amnesty text-yellow-amnesty hover:bg-yellow-amnesty hover:text-gray-amnesty"
                  } `}
                onClick={() => setChosenGroup(2)}
              >
                Arrested in the protest
              </button>
              <button
                className={`transition-colors duration-150 font-bold w-full lg:w-1/3 text-xs md:text-sm 2xl:text-base py-1.5 ${chosenGroup === 3
                  ? "bg-yellow-amnesty text-gray-amnesty"
                  : "bg-gray-amnesty text-yellow-amnesty hover:bg-yellow-amnesty hover:text-gray-amnesty"
                  } `}
                onClick={() => setChosenGroup(3)}
              >
                Injured in the protest
              </button>
            </div>
          </div>
          <div className="h-32">
            <div className="flex items-end justify-between 2xl:justify-start">
              <div className="relative">
                <div className="absolute text-white font-bold text-4xl -right-6 md:-right-9 md:text-5xl top-0">
                  {chosenGroup === 1 ? "+" : ""}
                </div>
                <h3 className="text-white sm:text-7xl md:text-8xl lg:text-annop-rem-7 font-bold xl:text-9xl">
                  {groupDetail[chosenGroup - 1].numberOfChild}
                </h3>
              </div>
              <h4 className="text-white text-2xl md:text-3xl xl:text-5xl font-bold">
                CHILDREN
              </h4>
            </div>
            <div className="">
              <p className="text-white text-xs md:text-sm lg:text-base 2xl:text-lg mt-2 whitespace-pre-line max-h-24 2xl:max-h-80 overflow-auto scrollbar-amnesty">
                <span className="filter drop-shadow-lg ">
                  {groupDetail[chosenGroup - 1].desc}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-annop-w-55 hidden sm:flex items-center">
        <div
          style={{ height: "53vw", width: "53.69vw" }}
          className="h-2/3 w-full grid grid-cols-4 scale-75 transform gap-3 overflow-hidden"
        >
          <div
            style={{ transform: "translateY(-16.5vw)" }}
            className="grid grid-flow-row-dense gap-3.5"
          >
            {firstSlide}
          </div>
          <div
            style={{ transform: "translateY(-16.5vw)" }}
            className="grid grid-flow-row-dense gap-3.5"
          >
            {secondSlide}
          </div>
          <div
            style={{ transform: "translateY(-16.5vw)" }}
            className="grid grid-flow-row-dense gap-3.5"
          >
            {thirdSlide}
          </div>
          <div
            style={{ transform: "translateY(-16.5vw)" }}
            className="grid grid-flow-row-dense gap-3.5"
          >
            {fourthSlide}
          </div>
        </div>
      </div>


      {/* mobile */}
      <div className="flex sm:hidden h-full w-full flex-col justify-center">
        {/* <div className="flex h-2/6 items-center w-full"> */}
        <div className={`flex h-2/7 items-center overflow-x-auto ${isMobile ? "no-scrollbar" : "scrollbar-amnesty"} py-1`}>
          {mobileSlide}
        </div>

        <div>
          <h1 className="inline pr-2 text-xl leading-snug text-white bg-black font-bold">
            WHAT’S HAPPENING<br />IN THAILAND?
            <br />
          </h1>
          <p className="text-white font-bold text-sm">
            <span className="bg-black">
              Here are stories happening in Thailand
            </span>
            <br />
            <span className="bg-black">
              that you may not know.
            </span>
          </p>
        </div>
        <div>
          <h3 className="text-white text-2xl font-bold text-center">
            <div>
              {groupDetail[chosenGroup - 1].numberOfChild}
            </div>
            CHILDREN
          </h3>
        </div>
        <div>
          <p {...cancelhandlers} className="text-white text-xs mt-2 whitespace-pre-line max-h-12 overflow-y-auto scrollbar-amnesty">
            {groupDetail[chosenGroup - 1].desc}
          </p>
        </div>
        <div>
          <h2 className="inline text-white text-bold bg-black px-1 font-bold">
            Children are
          </h2>
          <div className="flex flex-row">
            <button
              className={`transition-colors duration-150 mr-1 border border-yellow-amnesty font-bold w-1/3 text-xs py-1.5 ${chosenGroup === 1
                ? "bg-yellow-amnesty text-gray-amnesty"
                : "bg-gray-amnesty text-yellow-amnesty hover:bg-yellow-amnesty hover:text-gray-amnesty"
                } `}
              onClick={() => setChosenGroup(1)}
            >
              Joined the protest
            </button>
            <button
              className={`transition-colors duration-150 mx-1 border border-yellow-amnesty font-bold w-1/3 text-xs py-1.5 ${chosenGroup === 2
                ? "bg-yellow-amnesty text-gray-amnesty"
                : "bg-gray-amnesty text-yellow-amnesty hover:bg-yellow-amnesty hover:text-gray-amnesty"
                } `}
              onClick={() => setChosenGroup(2)}
            >
              Arrested in the protest
            </button>
            <button
              className={`transition-colors duration-150 ml-1 border border-yellow-amnesty font-bold w-1/3 text-xs py-1.5 ${chosenGroup === 3
                ? "bg-yellow-amnesty text-gray-amnesty"
                : "bg-gray-amnesty text-yellow-amnesty hover:bg-yellow-amnesty hover:text-gray-amnesty"
                } `}
              onClick={() => setChosenGroup(3)}
            >
              Injured in the protest
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>
    </WheelController >
  );
};

export default Page3;
