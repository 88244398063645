import { useDispatch, useSelector } from 'react-redux';
import Skip from './comp/Skip';
import H2 from './comp/H2';
import YesNoButton from './comp/YesNoButton';
import ProcessPoint from './comp/ProcessPoint';

const Quiz2 = () => {
    const dispatch = useDispatch();
    const page2State = useSelector(state => state.componentReducer.page2State);
    const answerFunc = (ans) => {
        dispatch({ type: "UPDATE_PAGE2_STATE", payload: { currentQuiz: page2State.currentQuiz, answer: ans } })
    }
    return (
        <div className="flex flex-col p-6 sm:p-20 justify-center h-full">
            <div className="pb-10 w-full">
                <H2>
                    You can participate in a protest<br/>and won't face any violence from the government.
                </H2>
            </div>
            <div className="pb-16 sm:pb-32">
                <YesNoButton className="mr-2" textButton="Yes" onClick={() => answerFunc("YES")} />
                <YesNoButton className="ml-2" textButton="No" onClick={() => answerFunc("NO")} />
            </div>
            <div className="flex items-center">
                <ProcessPoint point={2} />
                <Skip />
            </div>
        </div>
    )
}

export default Quiz2;