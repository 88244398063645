import { page7Path } from "../../../utils/asset";
import { FiArrowUpRight } from "react-icons/fi";
import WheelController from "../../WheelController";
import { donationlink } from "../../../utils/asset";
import { useDispatch } from "react-redux";
import ShareSocial from "./ShareSocial";
import { useEffect } from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useMediaQuery } from "../../../utils/customHook";

const Page7 = () => {
    const dispatch = useDispatch();
    const isSmallEnough = useMediaQuery('(max-width: 640px)');
    const onWheelFuncUp = () => {
        dispatch({ type: "SET_PAGE_ACTION", payload: { page: 6 } })
    }
    
    useEffect(()=>{
        dispatch({type:"UPDATE_SCROLLING_STATE",payload:{scrolling:"top"}})
    },[])

    useEffect(() => {
        if (isMobile || isSmallEnough) {
            dispatch({ type: "UPDATE_SCROLL", payload: { scrollable: true } })
        }
        return ()=>{
            dispatch({ type: "UPDATE_SCROLL", payload: { scrollable: false } })
        }
    }, [isSmallEnough])
    return (
        <WheelController onWheelFuncUp={onWheelFuncUp} className="flex flex-col h-screen bg-black overflow-auto">
            {/* lg:bg-contain */}
            <div style={{ backgroundImage: `url(${isSmallEnough?page7Path.bgnewpinkmobile:page7Path.bgnewpink})` }} className="h-3/5 bg-cover bg-top  bg-no-repeat relative">
                <div className="flex flex-col justify-end h-full w-full px-3 lg:px-10 xl:px-12 pb-5">
                    <div>
                        <h1 className="align-middle inline-block text-yellow-amnesty font-bold text-4xl sm:text-4xl md:text-5xl lg:text-7xl xl:text-8xl 2xl:text-9xl">
                            CHILD IN PROTEST
                        </h1>
                        <a
                            className="relative align-middle bg-black hidden sm:inline-block border-yellow-amnesty border-2 text-yellow-amnesty text-3xl font-bold hover:bg-yellow-amnesty hover:text-black ml-8"
                            href={donationlink}
                            target="_blank"
                        >
                            <div className="absolute top-0 left-0 bottom-0 right-0 px-7 py-4 blur opacity-75 leading-none flex items-center divide-x divide-gray-600 shadow-hipbtn transition animate-glowing z-0"></div>
                            <div className=" px-7 py-4 leading-none flex items-center divide-x divide-gray-600 z-40">
                                Donate
                                <FiArrowUpRight className="inline align-top" />

                            </div>
                        </a>
                    </div>
                    <p className="text-yellow-amnesty font-black mb-2">
                        <span className="bg-black sm:text-base md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">
                        Because a safe space for youths to express their political opinions
                        is no less important than their dreams.
                        </span>
                    </p>
                    <a
                        className="sm:hidden relative align-middle bg-black inline-block border-yellow-amnesty border-2 text-yellow-amnesty text-3xl font-bold hover:bg-yellow-amnesty hover:text-black w-44"
                        href={donationlink}
                        target="_blank"
                    >
                        <div className="absolute top-0 left-0 bottom-0 right-0 px-7 py-4 blur opacity-75 leading-none flex items-center divide-x divide-gray-600 shadow-hipbtn transition animate-glowing z-0"></div>
                        <div className=" px-7 py-4 leading-none flex items-center divide-x divide-gray-600 z-40">
                            Donate
                            <FiArrowUpRight className="inline align-top" />

                        </div>
                    </a>
                </div>
                {/* <img
                    className="w-full h-full object-cover lg:object-contain object-center"
                    src={page7Path.bgnew}
                /> */}
            </div>
            {/* bottommmmm-------------- */}
            {/* desktop */}
            <div className="hidden sm:flex h-2/5 pt-5">
                <div className="flex flex-col w-5/12 justify-between px-3 lg:px-10 xl:px-12">
                    <div>
                        <h2 className="text-white text-4xl font-bold">Project Mission</h2>
                        <p className="text-gray-400 text-sm my-2">
                            We are a group of volunteers working to respond to the emergency
                            and protect Thai children under the age of 18 in protest areas.
                        </p>
                        <p className="text-gray-400 text-sm my-2">
                            Child in Protest strongly believes every child has the right to freely
                            exercise and express their hopes and dreams in a peaceful
                            assembly.
                        </p>
                    </div>
                    <div>
                        <img className="w-20" src={page7Path.amnestyLogo} />
                        <h3 className="text-gray-400 text-sm my-2 justify-self-end">
                            2021 Project by Amnesty International Thailand
                        </h3>
                    </div>
                </div>
                <div className="w-7/12 lg:w-5/12 xl:w-4/12 relative">
                    <h2 className="text-white text-4xl font-bold">
                        How your donation help?
                    </h2>
                    <p className="text-gray-400 text-sm my-2">
                        Your donation towards Child in Protest will contribute to protecting
                        young children in Thailand who are currently fighting for their
                        freedom of expression. Proceeds will go towards safety equipment,
                        identifying wristbands, and supporting our volunteers in their work.
                    </p>
                    <p className="text-gray-400 text-sm my-2">
                        Your support can help protect their dreams and maintain a safe space
                        that everyone deserves.
                    </p>
                </div>
                <ShareSocial className="absolute right-3 bottom-3" />
            </div>
            {/* mobile */}
            <div className="flex flex-col sm:hidden h-2/5 px-3">
                <ShareSocial className="my-5" />
                <div className="flex flex-col justify-between lg:px-10 xl:px-12">
                    <div>
                        <h2 className="text-white text-4xl font-bold">Project Mission</h2>
                        <p className="text-gray-400 text-sm my-2">
                            We are a group of volunteers working to respond to the emergency
                            and protect Thai children under the age of 18 in protest areas.
                        </p>
                        <p className="text-gray-400 text-sm my-2">
                            Child in Protest strongly believes every child has the right to freely
                            exercise and express their hopes and dreams in a peaceful
                            assembly.
                        </p>
                    </div>
                </div>
                <div className="relative">
                    <h2 className="text-white text-4xl font-bold">
                        How your donation help?
                    </h2>
                    <p className="text-gray-400 text-sm my-2">
                        Your donation towards Child in Protest will contribute to protecting
                        young children in Thailand who are currently fighting for their
                        freedom of expression. Proceeds will go towards safety equipment,
                        identifying wristbands, and supporting our volunteers in their work.
                    </p>
                    <p className="text-gray-400 text-sm my-2">
                        Your support can help protect their dreams and maintain a safe space
                        that everyone deserves.
                    </p>
                </div>
                <div>
                    <img className="w-20" src={page7Path.amnestyLogo} />
                    <h3 className="text-gray-400 text-sm my-2 justify-self-end">
                        2021 Project by Amnesty International Thailand
                    </h3>
                </div>
            </div>
        </WheelController>
    );
};

export default Page7;
