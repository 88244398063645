import { motion, AnimatePresence } from 'framer-motion';
import ISEE from './isee1';
import IHEAR from './ihear2';
import IFEEL from './ifeel3';
import WheelController from '../../WheelController';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import VideoPlayer from "../../VideoPlayer";
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos, MdOutlineClose } from 'react-icons/md'
import { page4Content } from '../../../utils/asset';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.75)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0,0,0,0)',
        border: 0,
        borderRadius: 0,
        padding: 0
    },
};

Modal.setAppElement('#root');

const Page4 = () => {
    const dispatch = useDispatch();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedModal, setSelectedModal] = useState({ page: 'isee', seq: 0 });
    const [wheelEnable, setWheelEnable] = useState(false)

    const page4State = useSelector(state => state.componentReducer.page4State);

    function openModal(page, seq) {
        setSelectedModal({ page, seq });
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        setTimeout(() => {
            setWheelEnable(true);
        }, 3000)
        return () => setWheelEnable(false);
    }, [page4State.currentPage])

    const onWheelFuncDown = () => {
        if (wheelEnable) {
            if (!modalIsOpen) {
                if (page4State.currentPage < 3) {
                    dispatch({ type: "UPDATE_PAGE4_STATE", payload: { currentPage: page4State.currentPage + 1 } })
                } else {
                    dispatch({ type: "SET_PAGE_ACTION", payload: { page: 5 } })
                }
            }
        }
    }

    const onWheelFuncUp = () => {
        if (wheelEnable) {
            if (!modalIsOpen) {
                if (page4State.currentPage > 1) {
                    dispatch({ type: "UPDATE_PAGE4_STATE", payload: { currentPage: page4State.currentPage - 1 } })
                } else {
                    dispatch({ type: "SET_PAGE_ACTION", payload: { page: 3 } })
                }
            }
        }
    }

    const backwardModal = () => {
        if (selectedModal.seq === 0) {
            setSelectedModal({ page: selectedModal.page, seq: 3 })
        } else {
            setSelectedModal({ page: selectedModal.page, seq: selectedModal.seq - 1 })
        }
    }
    const forwardModal = () => {
        if (selectedModal.seq === 3) {
            setSelectedModal({ page: selectedModal.page, seq: 0 })
        } else {
            setSelectedModal({ page: selectedModal.page, seq: selectedModal.seq + 1 })
        }
    }

    const currentContent = page4Content[selectedModal.page][selectedModal.seq]

    const fadein = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 1
            }
        },
        exit: {
            opacity: 1,
            transition: {
                duration: 1
            }
        },
    }

    const slideinout = {
        hidden: {
            x: '100vw'
        },
        visible: {
            x: 0,
            transition: {
                type: 'tween',
                duration: 0.5
            }
        },
        exit: {
            x: '-100vw',
            transition: {
                type: 'tween',
                duration: 0.3
            }
        },
    }

    return (
        <WheelController onWheelFuncDown={onWheelFuncDown} onWheelFuncUp={onWheelFuncUp} className="h-screen">
            <AnimatePresence initial={false}>
                {page4State.currentPage === 1 ? <motion.div className="h-full w-full absolute" variants={fadein} initial="hidden" animate="visible" exit="exit" transition={{ duration: 1 }} key="e1"><ISEE openModal={openModal} closeModal={closeModal} setSelectedModal={setSelectedModal} /></motion.div> : null}
                {page4State.currentPage === 2 ? <motion.div className="h-full w-full absolute" variants={fadein} initial="hidden" animate="visible" exit="exit" transition={{ duration: 1 }} key="e2"><IHEAR modalIsOpen={modalIsOpen} openModal={openModal} closeModal={closeModal} setSelectedModal={setSelectedModal} /></motion.div> : null}
                {page4State.currentPage === 3 ? <motion.div className="h-full w-full absolute" variants={fadein} initial="hidden" animate="visible" exit="exit" transition={{ duration: 1 }} key="e3"><IFEEL openModal={openModal} closeModal={closeModal} setSelectedModal={setSelectedModal} /></motion.div> : null}
            </AnimatePresence>
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                closeTimeoutMS={300}
                onRequestClose={closeModal}
                // overlayClassName="fixed bg-black opacity-75 top-0 left-0 right-0 bottom-0"
                // className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="flex flex-col items-center text-white bg-black border border-yellow-amnesty p-14 sm:py-8 sm:px-12 md:px-14 md:py-10 lg:p-14 xl:p-24 max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-5xl overflow-hidden">
                    <MdOutlineClose
                        className="absolute top-5 right-5 text-yellow-amnesty text-3xl border border-yellow-amnesty rounded-full hover:text-white hover:border-white cursor-pointer w-10 h-10 p-1"
                        onClick={closeModal}
                    />
                    <MdOutlineArrowBackIos
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-4xl cursor-pointer hover:text-yellow-amnesty"
                        onClick={backwardModal}
                    />
                    <MdOutlineArrowForwardIos
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-4xl cursor-pointer hover:text-yellow-amnesty"
                        onClick={forwardModal}
                    />

                    <AnimatePresence initial={false} exitBeforeEnter>
                        <motion.div variants={slideinout} initial="hidden" animate="visible" exit="exit" key={`video_page4_${currentContent.media}`}>
                            <div className="w-4/5 m-auto">
                                {
                                    currentContent.type === "video/youtube" &&
                                    <VideoPlayer src={currentContent.media} type="video/youtube" />
                                }
                                {
                                    currentContent.type === "video" &&
                                    <VideoPlayer src={currentContent.media} />
                                }
                                {
                                    currentContent.type === "image" &&
                                    <motion.img src={currentContent.media} />
                                }
                            </div>
                            <h2 className="font-bold text-xl w-full mt-8">{currentContent.topic}</h2>
                            <p className="text-sm w-full">{currentContent.detail}</p>
                        </motion.div>
                    </AnimatePresence>
                </div>
            </Modal>
        </WheelController>
    )
}

export default Page4;