import { FaChevronDown } from 'react-icons/fa'
import { BsChevronDown,BsChevronUp, BsChevronCompactDown } from 'react-icons/bs'
import { VscChevronDown } from 'react-icons/vsc'
import { motion } from 'framer-motion'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const ScrollSign = ({ scroll = true, onClick=null }) => {
    return (
        <>
            <MobileView>
                <div onClick={onClick} className={`flex flex-col items-center ${onClick?"cursor-pointer":""}`}>
                    <div>
                        {scroll ? <p className="bg-black text-yellow-amnesty text-center font-bold text-sm px-1 mb-1">Slide Up</p> : null}
                    </div>
                    <div className="bg-gray-amnesty border border-yellow-amnesty rounded-full shadow-lg">
                        <div className="flex justify-center items-center text-yellow-amnesty text-xl w-8 h-8 sm:text-2xl sm:w-10 sm:h-10 lg:text-3xl lg:w-11 lg:h-11  transform rotate-180">
                            {/* <motion.span initial={{ y: 1 }} animate={{ y: -5 }} transition={{ duration: 1, yoyo: Infinity }} className="mt-1"><BsChevronUp /></motion.span> */}
                            <BsChevronDown className="animate-bounce mt-1"/>
                        </div>
                    </div>
                </div>
            </MobileView>
            <BrowserView>
                <div onClick={onClick} className={`flex flex-col items-center ${onClick?"cursor-pointer":""}`}>
                    <div>
                        {scroll ? <p className="bg-black text-yellow-amnesty text-center font-bold text-sm px-1 mb-1">Scroll down</p> : null}
                    </div>
                    <div className="bg-gray-amnesty border border-yellow-amnesty rounded-full shadow-lg">
                        {/* <FaChevronDown className="block text-yellow-amnesty m-auto text-5xl bg-black rounded-full p-2" /> */}
                        <div className="flex justify-center items-center text-yellow-amnesty text-xl w-8 h-8 sm:text-2xl sm:w-10 sm:h-10 lg:text-3xl lg:w-11 lg:h-11">
                            {/* <motion.span initial={{ y: -1 }} animate={{ y: 5 }} transition={{ duration: 1, yoyo: Infinity }} className="mt-1"><BsChevronDown /></motion.span> */}
                            <BsChevronDown className="animate-bounce mt-3"/>
                        </div>
                    </div>
                </div>
            </BrowserView>
        </>
    )
}

export default ScrollSign;