const ProcessPoint = ({ point = 1 }) => {
    return (
        // <div className="">
        <>
            <div className="inline-block mr-2 w-6 h-6 bg-yellow-amnesty border-yellow-amnesty border rounded-full">
            </div>
            <div className={`inline-block w-6 h-6 ${point > 1 ? 'bg-yellow-amnesty' : 'bg-gray-amnesty'} border-yellow-amnesty border rounded-full`}>
            </div>
            <div className={`inline-block ml-2 w-6 h-6 ${point > 2 ? 'bg-yellow-amnesty' : 'bg-gray-amnesty'} border-yellow-amnesty border rounded-full`}>
            </div>
        </>
        // </div>
    )
}

export default ProcessPoint;