import { motion, AnimatePresence, useViewportScroll, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page1 from './components/pages/page1';
import Page2 from './components/pages/page2';
import Page3 from './components/pages/page3';
import Page4 from './components/pages/page4';
import Page5 from './components/pages/page5';
import Page6 from './components/pages/page6';
import Page7 from './components/pages/page7';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { weblink,donationlink } from './utils/asset'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from 'react-modal';
import { MdOutlineClose } from 'react-icons/md'
import TagManager from 'react-gtm-module'
import { FaFacebookF, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { IoCodeSharp } from "react-icons/io5";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";


// import 'plyr/dist/plyr.css'
// import 'video.js/dist/video-js.css'
// import "video.js/dist/video-js.css";

const tagManagerArgs = {
  gtmId: 'GTM-WMK9QLR'
}
TagManager.initialize(tagManagerArgs)


const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.75)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0,0,0,0)',
    border: 0,
    borderRadius: 0,
    padding: 0
  },
};

Modal.setAppElement('#root');

const AnimateRouting = () => {
  const componentReducer = useSelector(state => state.componentReducer);
  const currentPage = componentReducer.currentPage;

  const fadein = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1
      }
    },
    exit: {
      opacity: 1,
      transition: {
        duration: 1
      }
    },
  }

  const upin = {
    hidden: {
      y: '100vw'
    },
    visible: {
      y: 0,
      transition: {
        type: 'tween',
        duration: 0.8
      }
    },
    exit: {
      y: 0
    },
  }

  return (
    <AnimatePresence initial={false}>
      {/* <Page5 /> */}
      {currentPage === 7 && <motion.div className="absolute w-full h-full top-0" key="page7_main" variants={upin} initial="hidden" animate="visible" exit="exit"><Page7 /></motion.div>}
      {currentPage === 6 && <motion.div className="absolute w-full h-full top-0" key="page6_main" variants={fadein} initial="hidden" animate="visible" exit="exit"><Page6 /></motion.div>}
      {currentPage === 5 && <motion.div className="absolute w-full h-full top-0" key="page5_main" variants={fadein} initial="hidden" animate="visible" exit="exit"><Page5 /></motion.div>}
      {currentPage === 4 && <motion.div className="absolute w-full h-full top-0" key="page4_main" variants={fadein} initial="hidden" animate="visible" exit="exit"><Page4 /></motion.div>}
      {currentPage === 3 && <motion.div className="absolute w-full h-full top-0" key="page3_main" variants={upin} initial="hidden" animate="visible" exit="exit"><Page3 /></motion.div>}
      {currentPage === 2 && <motion.div className="absolute w-full h-full top-0" key="page2_main" variants={upin} initial="hidden" animate="visible" exit="exit"><Page2 /></motion.div>}
      {currentPage === 1 && <motion.div className="absolute w-full h-full top-0" key="page1_main" variants={fadein} initial="hidden" animate="visible" exit="exit"><Page1 /></motion.div>}
      {/* <Page4 /> */}
    </AnimatePresence>
  )
}

function App() {
  const dispatch = useDispatch();
  const componentReducer = useSelector(state => state.componentReducer);
  const currentPage = componentReducer.currentPage;
  const scrollable = componentReducer.scrollable;

  const [modalIsOpen, setIsOpen] = useState(false);
  useEffect(() => {
    // original method to disable scroll
    // document.body.style.overflow = "hidden"

    // use this package, it's very popular
    dispatch({ type: "UPDATE_TEST_STATE", payload: { testState: "eiei" } })
  }, [])
  // dispatch({ type: "UPDATE_SCROLL", payload: { scrollable:false } })

  useEffect(() => {
    const bd = document.querySelector("body");
    if (scrollable) {
      enableBodyScroll(bd);
    } else {
      disableBodyScroll(bd);
    }
  }, [scrollable])
  const dropIn = {
    hidden: {
      y: '-100vh',
      opacity: 0,
      transition: {
        duration: 0.5
      }
    },
    visible: {
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.5
      }
    },
    exit: {
      y: '100vh',
      opacity: 1,
      transition: {
        // delay:11
        duration: 0.5
      }
    }
  }
  function openModal(modalnum) {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


  return (
    <div className="h-screen w-screen relative">
      <div className="absolute top-0 h-full w-full">
        <AnimateRouting />
      </div>
      <div className="flex items-center justify-between fixed bg-black top-0 left-0 w-full h-10 sm:h-16">
        <a href="/" className="flex items-center justify-between h-4/5 w-56 m-0 sm:ml-10">
          <img className="h-full" src="/asset/logo_amnesty_header.png" />
          <div className="text-white font-bold p-0 sm:pr-3 text-sm sm:text-base text-center sm:text-left ">Child in Protest</div>
        </a>
        <div className="flex sm:block">
          <a href={donationlink} target="_blank" className="font-bold text-yellow-amnesty bg-gray-amnesty-2 px-3 py-1 mx-1 sm:px-8 sm:py-2 sm:mx-4 hover:bg-yellow-amnesty hover:text-black">Donate</a>
          <button
            className="font-bold text-yellow-amnesty bg-gray-amnesty-2 px-3 py-1 mx-1 sm:px-8 sm:py-2 sm:mx-4 hover:bg-yellow-amnesty hover:text-black"
            onClick={openModal}
          >
            Share
          </button>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        closeTimeoutMS={300}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="bg-black border border-yellow-amnesty px-3 py-5 sm:p-5 md:p-6 lg:p-8 xl:p-12 max-w-lg overflow-hidden">
          <MdOutlineClose
            className="absolute top-2 right-2 text-yellow-amnesty text-3xl border border-yellow-amnesty rounded-full hover:text-white hover:border-white cursor-pointer w-8 h-8 p-1"
            onClick={closeModal}
          />
          <div className="text-white mb-6">
            <h3 className="font-bold text-3xl text-center sm:text-left mb-2">Share</h3>
            <p className="text-center sm:text-left">On behalf of Thai children who currently fighting for their dream, We are thankful for your support.</p>
          </div>
          <div className="text-center">
            <FacebookShareButton url={weblink}>
              <button className="text-white text-4xl border-white border-2 rounded-full p-2 mx-2 align-middle hover:text-yellow-amnesty hover:border-yellow-amnesty">
                <FaFacebookF />
              </button>
            </FacebookShareButton>
            <TwitterShareButton url={weblink}>
              <button className="text-white text-4xl border-white border-2 rounded-full p-2 mx-2 align-middle hover:text-yellow-amnesty hover:border-yellow-amnesty">
                <FaTwitter />
              </button>
            </TwitterShareButton>
            <WhatsappShareButton url={weblink}>
              <button className="text-white text-4xl border-white border-2 rounded-full p-2 mx-2 align-middle hover:text-yellow-amnesty hover:border-yellow-amnesty">
                <FaWhatsapp />
              </button>
            </WhatsappShareButton>
            <CopyToClipboard
              text={weblink}
              onCopy={() => alert("Copy to clipboard")}
            >
              <button
                className="text-white text-4xl border-white border-2 rounded-full p-2 mx-2 align-middle hover:text-yellow-amnesty hover:border-yellow-amnesty"
              // onClick={() => { navigator.clipboard.writeText(weblink); alert("copy link to clipboard!") }}
              >
                <IoCodeSharp />
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </Modal>
    </div>
    // scroll down เลื่อนล่างขึ้นบน
    // new york times

  );
}

export default App;
