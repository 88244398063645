import { useEffect, useRef, useState } from "react";
import Plyr from "plyr";
import VideoPlayer from "../../VideoPlayer";
import videojs from "video.js";
import 'videojs-youtube'
// videojs.registerPlugin('videojs-youtube')
import { AnimatePresence, motion } from "framer-motion";
import { page4Content } from "../../../utils/asset";
import CardMobile from "./components/CardMobile";

const ISEE = ({ openModal, closeModal, setSelectedModal }) => {
    const [animatePic, setAnimatePic] = useState(-2);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        let intervalTimer
        if (animatePic !== 3) {
            intervalTimer = setInterval(() => {
                setAnimatePic(animatePic + 1);
            }, 700)
        }

        return () => {
            clearInterval(intervalTimer)
        }
    }, [animatePic])


    const animatePicArr = [
        "/asset/iseeihearifeel/isee/picanimate/1.png",
        "/asset/iseeihearifeel/isee/picanimate/2.png",
        "/asset/iseeihearifeel/isee/picanimate/3.png",
        "/asset/iseeihearifeel/isee/picanimate/4.png"
    ]

    const animateImgOnload = () => {
        if (animatePic === 3) {
            setTimeout(() => {
                setShowContent(true)
            }, 300)
        }
    }

    return (
        <>
            <div className="hidden sm:flex flex-row bg-black w-full h-full">
                <div className="flex flex-col w-1/3 pt-16 xl:pb-6 pl-16">
                    <div className="h-1/6">
                        <h1 className="text-yellow-amnesty text-7xl font-bold pt-10">#ISEE</h1>
                        <h2 className="text-gray-400 mt-5">Come and See Through Their Eyes</h2>
                    </div>
                    <div className="flex h-5/6 items-center justify-center" >
                        <img onLoad={animateImgOnload} className="h-full w-auto object-contain" src={animatePicArr[animatePic]} />
                    </div>
                </div>
                {showContent ?
                    <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }} className="w-2/3 flex justify-center flex-row pt-16 pb-6 px-12 xl:px-32">
                        <div className="grid grid-cols-2 gap-2 max-h-full overflow-hidden">
                            <div
                                className="transition-all duration-200 p-3 cursor-pointer hover:border-yellow-amnesty border border-transparent hover:border-opacity-100"
                                onClick={() => openModal('isee', 0)}
                            >
                                <img src={page4Content.isee[0].media} />
                                <h3 className="text-white font-bold text-lg line-clamp-2">{page4Content.isee[0].topic}</h3>
                                <p className="text-white text-sm line-clamp-6 sm:line-clamp-5 md:line-clamp-4 lg:line-clamp-3">
                                    {page4Content.isee[0].detail}
                                </p>
                            </div>
                            <div
                                className="transition-all duration-200 p-3 cursor-pointer hover:border-yellow-amnesty border border-transparent hover:border-opacity-100"
                                onClick={() => openModal('isee', 1)}
                            >
                                <img src={page4Content.isee[1].media} />
                                <h3 className="text-white font-bold text-lg line-clamp-2">{page4Content.isee[1].topic}</h3>
                                <p className="text-white text-sm line-clamp-6 sm:line-clamp-5 md:line-clamp-4 lg:line-clamp-3">
                                    {page4Content.isee[1].detail}
                                </p>
                            </div>
                            <div
                                className="transition-all duration-200 p-3 cursor-pointer hover:border-yellow-amnesty border border-transparent hover:border-opacity-100"
                                onClick={() => openModal('isee', 2)}
                            >
                                <VideoPlayer src={page4Content.isee[2].media} disablePlay />
                                <h3 className="text-white font-bold text-lg line-clamp-2">{page4Content.isee[2].topic}</h3>
                                <p className="text-white text-sm line-clamp-6 sm:line-clamp-5 md:line-clamp-4 lg:line-clamp-3">
                                    {page4Content.isee[2].detail}
                                </p>
                            </div>
                            <div
                                className="transition-all duration-200 p-3 cursor-pointer hover:border-yellow-amnesty border border-transparent hover:border-opacity-100"
                                onClick={() => openModal('isee', 3)}
                            >
                                <VideoPlayer src={page4Content.isee[3].media} disablePlay />
                                <h3 className="text-white font-bold text-lg line-clamp-2">{page4Content.isee[3].topic}</h3>
                                <p className="text-white text-sm line-clamp-6 sm:line-clamp-5 md:line-clamp-4 lg:line-clamp-3">
                                    {page4Content.isee[3].detail}
                                </p>
                            </div>
                        </div>
                    </motion.div>
                    :
                    <div className="w-2/3 flex justify-center flex-row pt-16 pb-6 px-12 xl:px-32 ">
                    </div>
                }
            </div>
            <div className="flex sm:hidden flex-col bg-black w-full h-full">
                <div className="flex flex-col h-full justify-center">
                    <div className="flex flex-col h-2/8 justify-end">
                        <h1 className="text-yellow-amnesty text-5xl font-bold">#ISEE</h1>
                        <h2 className="text-gray-400">Come and See Through Their Eyes</h2>
                    </div>
                    <div className="flex h-6/8 items-center justify-center p-5" >
                        <img onLoad={animateImgOnload} className="h-full w-auto object-contain -mt-32" src={animatePicArr[animatePic]} />
                    </div>
                </div>
                {showContent ?
                    <CardMobile contentArr={page4Content.isee}/>
                    :
                    null
                }
            </div>
        </>
    )
}

export default ISEE;