const Page5Desktop = ({randomContent,openModal})=>{
    return (
        <div className="hidden sm:flex flex-col p-0 sm:py-16 sm:px-8 lg:py-16 lg:px-16 w-full h-full">
                <div className="my-10 lg:my-5">
                    <h1 className="text-yellow-amnesty font-bold text-5xl">Memories<br/>Present<br/>Wish</h1>
                </div>
                <div className="flex w-full h-full">
                    <div className="flex flex-col w-1/3 mr-4 cursor-pointer" onClick={() => openModal(0)}>
                        <div style={{ backgroundImage: `url(${randomContent[0].picPath})`, width: '29vw', height: '29vw' }} className="relative bg-cover bg-center bg-no-repeat w-full">
                            {/* dont forget object-fit */}
                            <h2 className="absolute bottom-0 line-clamp-3 bg-black text-white m-2 p-1 font-bold">{randomContent[0].descOnPic}</h2>
                        </div>
                        <div className="h-1/6 text-white px-3">
                            <h3 className="font-bold">{randomContent[0].topic}</h3>
                            {/* <p>Description Description Description</p> */}
                        </div>
                    </div>
                    <div className="flex flex-col w-1/3 mx-4 cursor-pointer" onClick={() => openModal(1)}>
                        <div style={{ backgroundImage: `url(${randomContent[1].picPath})`, width: '29vw', height: '29vw' }} className="relative bg-cover bg-center bg-no-repeat w-full">
                            {/* dont forget object-fit */}
                            <h2 className="absolute bottom-0 line-clamp-3 bg-black text-white m-2 p-1 font-bold">{randomContent[1].descOnPic}</h2>
                        </div>
                        <div className="h-1/6 text-white px-3">
                            <h3 className="font-bold">{randomContent[1].topic}</h3>
                            {/* <p>Description Description Description</p> */}
                        </div>
                    </div>
                    <div className="flex flex-col w-1/3 ml-4 cursor-pointer" onClick={() => openModal(2)}>
                        <div style={{ backgroundImage: `url(${randomContent[2].picPath})`, width: '29vw', height: '29vw' }} className="relative bg-cover bg-center bg-no-repeat w-full">
                            {/* dont forget object-fit */}
                            <h2 className="absolute bottom-0 line-clamp-3 bg-black text-white m-2 p-1 font-bold">{randomContent[2].descOnPic}</h2>
                        </div>
                        <div className="h-1/6 text-white px-3">
                            <h3 className="font-bold">{randomContent[2].topic}</h3>
                            {/* <p>Description Description Description</p> */}
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Page5Desktop;