import { motion } from "framer-motion";
import { useState } from "react";
import PositionPoint from "./PositionPoint";
import { useSwipeable } from "react-swipeable";

const Page5Mobile = ({ randomContent, openModal }) => {
    const [currentContent, setCurrentContent] = useState(1);
    const variants = {
        tooLeft: { x: '-130vw', scale: 0.7 },
        toLeft: { x: '-65vw', scale: 0.7 },
        toCenter: { x: '0vw' },
        toRight: { x: '65vw', scale: 0.7 },
        tooRight: { x: '130vw', scale: 0.7 },
        transition: {
            type: "tween",
            duration: 1
        }
    }

    const swipeLeft = () => {
        if (currentContent < 2) {
            setCurrentContent(currentContent + 1)
        }
    }

    const swipeRight = () => {
        if (currentContent > 0) {
            setCurrentContent(currentContent - 1)
        }
    }

    const handlers = useSwipeable({
        onSwipedLeft: swipeLeft,
        onSwipedRight: swipeRight
    });

    const FirstAnimate = () => {
        if (currentContent === 0) {
            return "toCenter"
        } else if (currentContent === 1) {
            return "toLeft"
        } else if (currentContent === 2) {
            return "tooLeft"
        }
    }

    const SecondAnimate = () => {
        if (currentContent === 0) {
            return "toRight"
        } else if (currentContent === 1) {
            return "toCenter"
        } else if (currentContent === 2) {
            return "toLeft"
        }
    }

    const ThirdAnimate = () => {
        if (currentContent === 0) {
            return "tooRight"
        } else if (currentContent === 1) {
            return "toRight"
        } else if (currentContent === 2) {
            return "toCenter"
        }
    }

    return (
        <div  {...handlers} className="flex sm:hidden flex-col p-0 w-full h-full">
            <div className="h-1/8"></div>
            <div className="h-1/8 flex items-end text-yellow-amnesty text-3xl font-bold ml-8">Memories<br />Present<br />Wish</div>
            <div style={{ transformStyle: 'preserve-3d' }} className="h-6/8 overflow-hidden">
                <div className="h-3/5 w-full relative">
                    <div className={`transform -translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2 ${currentContent === 0 ? "z-50" : ""}`}>
                        <motion.div variants={variants} initial={{ x: 0 }} animate={FirstAnimate()} transition="transition" className=" flex flex-col cursor-pointer">
                            <div style={{ backgroundImage: `url(${randomContent[0].picPath})`, width: '40vh', height: '40vh' }} className="relative bg-cover bg-center bg-no-repeat w-full">
                                {/* dont forget object-fit */}
                                <h2 className="absolute bottom-0 line-clamp-3 bg-black text-white m-2 p-1 font-bold">{randomContent[0].descOnPic}</h2>
                            </div>
                            <div className="h-1/6 text-white mx-3">
                                <h3 className="font-bold">{randomContent[0].topic}</h3>
                            </div>
                        </motion.div>
                    </div>
                    <div className={`transform -translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2 ${currentContent === 1 ? "z-50" : ""}`}>
                        <motion.div variants={variants} initial={{ x: 0 }} animate={SecondAnimate()} transition="transition" className=" flex flex-col cursor-pointer">
                            <div style={{ backgroundImage: `url(${randomContent[1].picPath})`, width: '40vh', height: '40vh' }} className="relative bg-cover bg-center bg-no-repeat w-full">
                                {/* dont forget object-fit */}
                                <h2 className="absolute bottom-0 line-clamp-3 bg-black text-white m-2 p-1 font-bold">{randomContent[1].descOnPic}</h2>
                            </div>
                            <div className="h-1/6 text-white mx-3">
                                <h3 className="font-bold">{randomContent[1].topic}</h3>
                            </div>
                        </motion.div>
                    </div>
                    <div className={`transform -translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2 ${currentContent === 2 ? "z-50" : ""}`}>
                        <motion.div variants={variants} initial={{ x: 0 }} animate={ThirdAnimate()} transition="transition" className=" flex flex-col cursor-pointer">
                            <div style={{ backgroundImage: `url(${randomContent[2].picPath})`, width: '40vh', height: '40vh' }} className="relative bg-cover bg-center bg-no-repeat w-full">
                                {/* dont forget object-fit */}
                                <h2 className="absolute bottom-0 line-clamp-3 bg-black text-white m-2 p-1 font-bold">{randomContent[2].descOnPic}</h2>
                            </div>
                            <div className="h-1/6 text-white mx-3">
                                <h3 className="font-bold">{randomContent[2].topic}</h3>
                            </div>
                        </motion.div>
                    </div>
                </div>
                <div className="h-2/5 relative">
                    <div className="absolute top-5 left-1/2 transform -translate-x-1/2">
                        <PositionPoint point={currentContent} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page5Mobile;