const initialState = {
    testState: null,
    currentPage: 3,
    page2State: {
        currentQuiz: 1,
        answer: null,
        lastskip: { lastquiz: 1, lastans: null }
    },
    scrollable: true,
    page4State: {
        currentPage: 1
    },
    scrolling:"top"
};
const componentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_TEST_STATE_SUCCESS":
            return { ...state, testState: action.payload.testState };
        case "SET_PAGE_ACTION":
            return { ...state, currentPage: action.payload.page };
        case "UPDATE_PAGE2_STATE":
            return { ...state, page2State: { ...state.page2State, currentQuiz: action.payload.currentQuiz, answer: action.payload.answer, lastskip: { lastquiz: action.payload.currentQuiz, lastans: action.payload.answer } } };
        case "UPDATE_PAGE2_SKIP":
            return { ...state, page2State: { ...state.page2State, currentQuiz: action.payload.currentQuiz, answer: action.payload.answer } };
        case "UPDATE_SCROLL":
            return { ...state, scrollable: action.payload.scrollable };
        case "UPDATE_PAGE4_STATE":
            return { ...state, page4State: { currentPage: action.payload.currentPage } };
        case "UPDATE_SCROLLING_STATE":
            return { ...state, scrolling: action.payload.scrolling };
        default:
            return state;
    }
};

export default componentReducer;
