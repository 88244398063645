import { call, put } from "redux-saga/effects";
// import axios from "axios";
// const RANDOM_BEER_API = "https://random-data-api.com/api/beer/random_beer";

export function* testAction(action) {
    try {
        const { testState } = action.payload
        yield put({
            type: "UPDATE_TEST_STATE_SUCCESS",
            payload: { testState: testState },
        });
    } catch (e) {
        yield put({ type: "UPDATE_TEST_STATE_FAIL" });
    }
}