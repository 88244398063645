import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Quiz1 from './quiz1'
import Result1 from './result1';
import Quiz2 from './quiz2'
import Result2 from './result2';
import Quiz3 from './quiz3'
import Result3 from './result3';
import Summarize from './summarize';
import WheelController from '../../WheelController';
import { useMediaQuery } from '../../../utils/customHook';

const bgImagePath = ['/asset/yesno/bg/bg_page2_1.jpeg', '/asset/yesno/bg/bg_page2_2.jpeg', '/asset/yesno/bg/bg_page2_3.jpeg', '/asset/yesno/bg/bg_page2_4.jpeg']

const ChangePage2 = ({ children, key, first = false }) => {
    return (
        <motion.div className="h-full" key={key} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0, transition: { delay: first ? 0.5 : 0, duration: 0.5 } }} exit={{ opacity: 0, y: -20, transition: { duration: 0.5 } }}>
            {children}
        </motion.div>
    )
}

const Page2 = () => {
    const page2State = useSelector(state => state.componentReducer.page2State);
    const dispatch = useDispatch();
    const onWheelFuncUp = () => {
        if (page2State.lastskip.lastquiz === page2State.currentQuiz && page2State.lastskip.lastans === page2State.answer) {
            dispatch({ type: "SET_PAGE_ACTION", payload: { page: 1 } })
        }
    }

    const isSmallEnough = useMediaQuery('(max-width: 640px)');

    useEffect(()=>{

        dispatch({type:"UPDATE_SCROLLING_STATE",payload:{scrolling:"top"}})
    },[page2State.currentQuiz,page2State.answer])

    useEffect(() => {
        if (isMobile || isSmallEnough) {
            dispatch({ type: "UPDATE_SCROLL", payload: { scrollable: true } })
        }
        return ()=>{
            dispatch({ type: "UPDATE_SCROLL", payload: { scrollable: false } })
        }
    }, [isSmallEnough])
    return (
        <WheelController
            key={`page2_img${page2State.currentQuiz - 1}`}
            style={{ backgroundImage: `url(${bgImagePath[page2State.currentQuiz - 1]})` }}
            className="absolute top-0 h-full w-full bg-white bg-cover bg-center bg-no-repeat overflow-auto sm:overflow-hidden"
            onWheelFuncUp={onWheelFuncUp}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 1, transition: { duration: 1 } }}
        >
            <AnimatePresence exitBeforeEnter>
                {
                    page2State.currentQuiz === 1 &&
                    (page2State.answer ? <ChangePage2 key="page2_result_1"><Result1 /></ChangePage2> : <ChangePage2 key="page2_quiz_1" first={true}><Quiz1 /></ChangePage2>)
                }
                {
                    page2State.currentQuiz === 2 &&
                    (page2State.answer ? <ChangePage2 key="page2_result_2"><Result2 /></ChangePage2> : <ChangePage2 key="page2_quiz_2" first={true}><Quiz2 /></ChangePage2>)
                }
                {
                    page2State.currentQuiz === 3 &&
                    (page2State.answer ? <ChangePage2 key="page2_result_3"><Result3 /></ChangePage2> : <ChangePage2 key="page2_quiz_3" first={true}><Quiz3 /></ChangePage2>)
                }
                {
                    page2State.currentQuiz === 4 &&
                    <ChangePage2 key="page2_summarize" first={true}><Summarize /></ChangePage2>
                }
            </AnimatePresence>
        </WheelController>
    )
}

export default Page2;