import { useDispatch } from "react-redux";
import { BsChevronDown, BsChevronCompactDown } from 'react-icons/bs'
import { VscChevronDown } from 'react-icons/vsc'
import { motion } from 'framer-motion'

const Skip = () => {
    const dispatch = useDispatch();
    const skipFunc = () => {
        dispatch({ type: "UPDATE_PAGE2_SKIP", payload: { currentQuiz: 4, answer: null } })
    }

    return (
        <>
            <button className="border-yellow-amnesty border bg-gray-amnesty text-yellow-amnesty font-bold px-1 mx-3 hover:bg-yellow-amnesty hover:text-gray-amnesty hover:border-gray-amnesty" onClick={skipFunc}>Skip</button>
            {/* <div className="inline-block bg-gray-amnesty border border-yellow-amnesty rounded-full shadow-lg">
                <div className="flex justify-center items-center text-yellow-amnesty text-xl w-8 h-8 sm:text-2xl sm:w-10 sm:h-10 lg:text-3xl lg:w-11 lg:h-11">
                <motion.span initial={{ y: -1 }} animate={{ y: 5 }} transition={{ duration: 1, yoyo: Infinity }} className="mt-1"><BsChevronDown /></motion.span>
                </div>
            </div> */}
        </>
    )
}

export default Skip;