import { motion, AnimatePresence } from 'framer-motion';
import { page2Path } from '../../../utils/asset';
import { useDispatch, useSelector } from 'react-redux';
import Skip from './comp/Skip';
import { useEffect, useRef, useState } from 'react';
import WheelController from '../../WheelController';
import H1 from './comp/H1';
import H2 from './comp/H2';
import ScrollSign from '../../ScrollSign';

const Summarize = () => {
    const dispatch = useDispatch();
    const page2State = useSelector(state => state.componentReducer.page2State);

    const [textState, setTextState] = useState(1);
    // const wheelcount = useRef(0);
    const [enableScroll, setEnableScroll] = useState(false);
    const [checkmoretemp, setCheckmoreTemp] = useState(false);

    const onScrollTrack = () => {
        if (enableScroll) {
            if (textState === 2 && checkmoretemp) {
                dispatch({ type: "SET_PAGE_ACTION", payload: { page: 3 } })
            }
            setTextState(2);
        }
    }

    const onWheelFuncUp = () => {
        if (enableScroll) {
            if (textState === 1) {
                dispatch({ type: "UPDATE_PAGE2_STATE", payload: { currentQuiz: page2State.lastskip.lastquiz, answer: page2State.lastskip.lastans } })
            }
        }
    }

    // useEffect(() => {
    //     if (textState === 2) {
    //         wheelcount.current = 0;
    //     }
    // }, [textState])

    return (
        <WheelController onWheelFuncDown={onScrollTrack} onWheelFuncUp={onWheelFuncUp} stateToReset={[textState]} className="w-full h-full relative">

            <div
                className="flex flex-col p-6 sm:pl-10 sm:py-10 justify-around h-full w-full sm:w-3/4"
            >
                <AnimatePresence exitBeforeEnter>
                    {textState === 1 &&
                        <motion.div key="summm_1" initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -20, opacity: 0 }} transition={{ duration: 1 }}>
                            <H2>
                                <p className="leading-tight text-2xl sm:text-4xl">
                                    Throughout the last few years, many protests
                                    have been organized by various groups.{"\n\n"}
                                    We have witnessed the united force of the
                                    younger generation who have gathered for
                                    their rights, calling for a better life and{"\n"}a
                                    brighter future.
                                </p>
                            </H2>
                        </motion.div>
                    }

                    {textState === 2 && <>
                        <motion.div key="summm_2" initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -20, opacity: 0 }} transition={{ duration: 1 }}>
                            <H2>
                                Here, we will see
                            </H2>
                            <H1>
                                <p className="text-4xl sm:text-7xl">
                                    <motion.span className="block sm:inline" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, duration: 1 }}>what has</motion.span>
                                    <motion.span className="block sm:inline" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2, duration: 1 }}>{' '}happened</motion.span>
                                    <motion.span className="block sm:inline" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3, duration: 1 }}>{' '}to them.</motion.span>
                                </p>
                            </H1>
                        </motion.div>
                    </>}

                </AnimatePresence>
                <div></div>
            </div>
            <AnimatePresence exitBeforeEnter onExitComplete={() => { setEnableScroll(false); setCheckmoreTemp(true) }}>
                {textState === 1 ? <motion.div key="scroll_sum_1" className="text-white absolute left-1/2 bottom-1/6 sm:bottom-10" initial={{ opacity: 0, y: 20, x:'-50%' }} animate={{ opacity: 1, y: 0, transition: { delay: 1, duration: 0.5 } }} exit={{ opacity: 0, y: -20, transition: { duration: 1 } }} onAnimationComplete={() => setEnableScroll(true)}>
                    <ScrollSign onClick={onScrollTrack} />
                </motion.div> : null}
                {textState === 2 ? <motion.div key="scroll_sum_2" className="text-white absolute left-1/2 bottom-1/6 sm:bottom-10" initial={{ opacity: 0, y: 20, x:'-50%' }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ delay: 4, duration: 0.5 }} onAnimationComplete={() => setEnableScroll(true)}>
                    <ScrollSign onClick={onScrollTrack} />
                </motion.div> : null
                }
            </AnimatePresence>
        </WheelController >
    )
}

export default Summarize;