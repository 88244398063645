export const page1Path = [
    '/asset/thennow/then-now_1.jpg',
    '/asset/thennow/then-now_2.jpg',
    '/asset/thennow/then-now_3.jpg',
    '/asset/thennow/then-now_4.jpg',
    '/asset/thennow/then-now_5.jpg',
    '/asset/thennow/then-now_6.jpg',
    '/asset/thennow/then-now_scroll_1.jpg',
    '/asset/thennow/then-now_scroll_2.jpg',
    '/asset/thennow/then-now_scroll_3.jpg',
    '/asset/thennow/then-now_scroll_4.jpg',
    '/asset/thennow/then-now_scroll_5.jpg',
]

export const page2Path = [
    '/asset/yesno/yesno1.jpeg',
    '/asset/yesno/yesno2.jpeg',
    '/asset/yesno/yesno3.jpeg',
]

export const page3Path = {
    bg: '/asset/whatshappening/bg_page3_1.jpeg',
    demo: '/asset/whatshappening/demo.jpeg',
    pic: [
        { path: '/asset/whatshappening/joined/1.png', group: 1 },
        { path: '/asset/whatshappening/joined/2.png', group: 1 },
        { path: '/asset/whatshappening/joined/3.png', group: 1 },
        { path: '/asset/whatshappening/joined/4.png', group: 1 },
        { path: '/asset/whatshappening/joined/5.png', group: 1,credit:"Photo: THE STANDARD" },
        { path: '/asset/whatshappening/joined/6.png', group: 1,credit:"Photo: THE STANDARD" },
        // 
        { path: '/asset/whatshappening/arrested/1.jpg', group: 2 },
        { path: '/asset/whatshappening/arrested/2.png', group: 2 },
        { path: '/asset/whatshappening/arrested/3.png', group: 2 },
        { path: '/asset/whatshappening/arrested/4.png', group: 2 },
        { path: '/asset/whatshappening/arrested/5.png', group: 2,credit:"Photo: THE STANDARD" },
        { path: '/asset/whatshappening/arrested/6.png', group: 2,credit:"Photo: THE STANDARD" },
        // 
        { path: '/asset/whatshappening/injured/1.png', group: 3,credit:"Photo: Sirachai Shin" },
        { path: '/asset/whatshappening/injured/2.png', group: 3 },
        { path: '/asset/whatshappening/injured/3.png', group: 3 },
        { path: '/asset/whatshappening/injured/4.png', group: 3 },
        { path: '/asset/whatshappening/injured/5.png', group: 3 },
        { path: '/asset/whatshappening/injured/6.png', group: 3,credit:"Photo: THE STANDARD" },
    ]
}

export const page7Path = {
    bg: '/asset/donation/bg_page7_1.jpeg',
    bgnew: '/asset/donation/bg_page7_1_1.jpeg',
    bgnewpink: '/asset/donation/bg_page7_1_2.jpg',
    bgnewpinkmobile: '/asset/donation/bg_page7_1_2_mb.jpeg',
    amnestyLogo: '/asset/donation/amnesty_logo.png'
}

export const page5Content = [
    {
        key: "content5_1",
        picPath: "/asset/mpw/1.png",
        topic: "Eddy, 18",
        descOnPic: "“A child from Isan (north-eastern Thailand) fighting for education reform in his hometown, believing that human rights will unite people.",
        desc1: "I was born in a middle-class family in Isan. My grandparents are into politics, and my parents value freedom of thought.\n\nWhen I was in middle school, it was a post-coup era - politics seemed like a way to instill fear, and it seemed irrelevant to our lives. Then came the 2019 election. I became more interested in politics because of a political party led by younger politicians.\nI started to see our nation's distorted structures affecting our lives; how the system supports the growth of 1% of the population at the cost of the other 99% who have to carry all the burdens.\n\nI just couldn't let this slide: it's a time bomb too big to ignore. That's why I studied politics more and discussed it with my friends.”",
        desc2: "My friends and I started an Order of Students in high school. We organized protests that focused on addressing education problems and education reform in our province. We wanted to point out that having quality education is essential. It shouldn't be a privilege reserved only for those in Bangkok.\n\nWe want people to be aware that education inequity exists. More people joined the protest than anticipated, and older locals who have long been fighting for the same issue also supported us. I don't see myself as an activist. I'm just an ordinary kid who wants to call out for a better life.\n\nI think change can start here: at least we've changed how people think, and my friends are now seeing the same problems that I see. Structural problems are now something we can talk to each other about during lunch in the school canteen. It's small yet significant towards change.",
        desc3: "In the future, I want to see human rights understanding in Thai society. It determines a way of living where we can co-exist without bloodshed. It might be a better moral armor than any religion.\n\nI want the people to really be the ones who own the country, and I want to see structural problems being solved. If we had good politics, we wouldn’t have to struggle to get into good universities in Bangkok. Our life quality would be better, and we wouldn’t have to be so worn down from demanding our rights to basic needs.\n\nProtesting may not solve all the problems: we still need thought-provoking campaigns, political parties, and academic work that support the same goal. I want to have a better life, and it seems like the most convenient solution is to move to another country.\n\nBut I still want to live here. I still want change. I’m one of the people who still believe there’s hope that Thailand could stand proud among other nations in this world. ",
    },
    {
        key: "content5_2",
        picPath: "/asset/mpw/2.png",
        topic: "Man, 20",
        descOnPic: "“A high school student whose political interests were sparked by a teacher. He stepped up to become a leading protester calling for a change in the Thai education system.”",
        desc1: "Before, I didn’t care about what the protest was calling for. I was even trying to protect the beliefs of the opposite side. My political interest started when I met a teacher in 10th grade. He made us think and question what we know about Thai society, Thai politics, and the protests.\n\nWe learned that politics and life are inseparable. It is about managing things that the government should provide for its people. Today, my mom and I live in a rented house, and the effects of the Covid-19 outbreak have hit us directly. Once I listened with a more open mind, I logically began to learn and change.\n\nSince then, I have joined some protests. I was scared that the situation may become dangerous. But then I questioned why there should be any danger since all we are doing is calling out the things impacting our lives. This should not be the case. I want to be a part of a movement that’s fighting for our future.",
        desc2: "This year, I have been elected as part of the student council. I'm an active student who wants to be involved both within and outside the school. My friends and I would get together to join the protest.\n\nThe teachers don't ban us from going - sometimes it feels like they're even actively supporting us. Our families and teachers allow us from going, but they're still worried about our safety. If you ask me whether I'm scared, I am. But our future is at stake.\nEven when there is a backlash, at least we get to try and take control of what our future will hold. It might not end up exactly the way we want.\n\nMaybe everything will not go according to what we are demanding, but doing something is still better than doing nothing at all.",
        desc3: "I still hope that our country will change, we can be more accepting of each other, and the politics improve. However, change takes a long time. I want to see everyone having equal opportunities to fundamental human rights, including quality education and in other aspects of life.\n\nI want our political structure to improve so that everyone has equal access to resources. I want Thailand to have that future. Education shouldn’t be a privilege, but everyone should equally access it, as education could dictate our future direction.\n\nI used to dream of becoming a government official, a police officer, or a soldier, but that dream doesn’t sit well with the reality of my socioeconomic status. When I graduate from high school, I plan to continue my studies in educational development.",
    },
    {
        key: "content5_3",
        picPath: "/asset/mpw/3.png",
        topic: "Khao-Too, 16",
        descOnPic: "“The journey of a 16-year-old boy. His curiosity started with his bodily autonomy and then the rights of a citizen in a democratic society.”",
        desc1: "From kindergarten to elementary school, the school forced me to cut my hair very short, and my friends would tease me for that. In secondary school, I started to question why we have to cut our hair this way - why can't it be cut another way?\nThat's when I began finding answers from the Ministry's Regulations.\n\nI talked with teachers from the student welfare department, but questioning their regulations still required more courage than I had. Then last year, I saw the movement campaigning for this issue from Bad Student, one of the student-led activist groups.\nIt touched me to the core. Since then, I started to realize the importance of politics in my daily life.\n\nThere's been a ton of movement happening on Twitter. Politically related hashtags are popping up every day in every aspect. I've learned that the regulation of our hairstyle is a tip of a very tall iceberg; like the curriculum, quality of life, public transportation, cost of living, urban planning, inequity, and the right to express demands for a better quality of life, which everyone should have. ",
        desc2: "I know I shouldn’t keep it only to myself. I had to share them to raise awareness and enable others to be a part of the solution. I started by sharing my thoughts on social media. Once my friends saw my posts, they asked questions like ‘What’s going on with you?’ and ‘Aren’t we too young to be so political?’. I responded that politics is related to everything around us. My friends started to understand this more when they woke up for online learning and walked on bad pavement.\nThey understood that politics is all around us.\n\nWhen school was still open, I was an outspoken student. There was a time when I showed the three-finger salute during the national anthem, and I got scolded by teachers for it. They said, ‘why did you do that?’, ‘what’s the point?’, or said this is dividing the society, and it makes you look stupid.’ My points were deducted. One time a teacher went on about what I did and ended up not teaching at all.\n\nThere’s a moment when I wondered what would have happened if the punishment had been more severe - like, what if they expelled me for my political opinion? I could not care less. I can still enroll in a non-formal education curriculum which only takes 2 years to graduate with a high-school equivalent degree. I’m not that happy to be in a school anyway. ",
        desc3: "I want everyone to have the right to express their opinion, to be able to say what they think, and to live in a society where everyone is equal. Voicing our opinions is necessary for our countries' development, as we'd get to be honest about our imperfections.\n\nEven if it takes a decade to get there, even when I'm old or relying on life-support by then, I'd be happy to know that the next generation will get to exercise their rights and live better lives than I did. I'm okay with that. ",
    },
    {
        key: "content5_4",
        picPath: "/asset/mpw/4.png",
        topic: "Ploy, 17",
        descOnPic: "“A dream of a young girl with a price nobody dares to pay.”",
        desc1: "I've heard about politics from the news since I was still in elementary school. Back then, I didn't understand what it was.\n\nWhen I was in middle school, politics became more intense. I began to saw a clearer picture of many problems in Thailand by using Twitter.\nI've learned that textbooks may not always tell the whole truth, and I began to understand our country's structural problems.\n\nI didn't know I could make a change at first, but then I saw a tweet from “Education for Liberation of Siam” recruiting a staff. I knew right away that it was a chance. That's when I started campaigning when I was 15, and have since worked with ELS, Bad Student, and “Prai Phol”",
        desc2: "At first, I didn't dare to speak about many of the problems in Thailand - I was scared I might get in trouble s from the law, so I focused on the education system. But after a while, I couldn't hold it in anymore. It was obvious these problems don't only affect students. There are structural problems, inequity, poverty, marginalization, and all these problems because a single group wants to dictate the country. I couldn't accept it. How can we tackle these problems if no one is willing to talk about the real problem?\n\nI fell out with my grandma - she is an ultra-royalist and stands on the other end of the spectrum. Every time I raised this topic, she'd start scolding me. We couldn't live together. So I packed my bags, left the house, and started living with my colleague. My dad called me with the condition that I had to go back and live with my grandma, or else he'd stop supporting me financially. I didn't go back, and my dad kept his word. I lost my relationship with my grandma and my dad, not to mention the 4 - 5 lawsuits that the state has filed against me.\n\nThis weight is heavy for a kid like me. Instead of living a life, My youth moment is about going to court and facing violence and harassment.  However, I'm continuing on this path because I don't want to grow up in a country that surrenders to the corrupted power.",
        desc3: "I wanted to see this country genuinely belong to the people. In a country that embraces diversity, the education system is strong, and schools teach us to respect ourselves and other people. I dream of a country where there’s state-provided welfare that supports quality of life. People from lower economic status can make a living, where people are aware of their rights to obtain a good life and know that the state must support them without labeling this support as a gift from anyone.\n\nI want to continue fighting in this country. Right now, I’m only 16, so if the court finds me guilty, the worst that would happen is they will send me to a juvenile detention center. But when I turn 18, things could get much more dangerous. When that day comes, I’m not sure if I’d have the strength to keep on fighting. But now that I can still fight, I still want to, until the day that I won’t be able to anymore.",
    },
    {
        key: "content5_5",
        picPath: "/asset/mpw/5.png",
        topic: "Poom, 17",
        descOnPic: "“A high schooler who took part in the political movement, fell apart with his family, privacy violated by the police, and got summoned to court.”",
        desc1: "I'm was born in a middle-class Chinese family living in Bangkok. My political interest started when I was in elementary school, around the red/yellow shirt conflict. I was curious why did protests happen every day?\n\nI began reading a lot of literature, and I particularly enjoyed writings about foreign histories. In 9th grade, I was exposed to Jit Phumisak's works, which inspired me a lot. I decided to be involved in political movements ever since. I started by focusing on education issues because I've always had a hard time accepting the authoritarianism of adults, like teachers in the school.\n\nMy family doesn't support me, and we got into a big fight last September. They are worried that I will be in danger. Deep down, I don't like the fact that they're trying to stop me, but I can't be mad at them.",
        desc2: "I dropped out of school last year due to political reasons, and I'm now preparing for an entrance exam to a new high school.\n\nI'm currently interested in problems about inequity, such as how Covid 19 is affecting those from lower economic status, and I got to talk to some of them during the protests.In the protests, the police harassed me, took a picture of me, followed me late at night after the protest ended, and even took a photo of the place I live. They even brought a psychologist from a juvenile court, threatening me and persuading me to participate in the protests.\n\nI was upset about it. I think every fight is a movement, and every protest has its dynamic. I feel that as a nation, one day, we will grow to accept the international standard of peace.",
        desc3: "In this country, we don't even have the right to live.\n\nThe country was not designed for its citizens to live a life. You cannot have a life that you can genuinely say it's yours unless you are rich because even middle-class people still have to work to stay alive.\nThere's no state-supported welfare for all. And those from lower economic status have almost no time to live their lives at all.\n\nI hope for a day when all Thai citizens will have equal rights. I want to see the military no longer involved in politics. I want to see the end of this cycle of coups we seem stuck in, and I want to see a transparent election.",
    },
    {
        key: "content5_6",
        picPath: "/asset/mpw/6.png",
        topic: "PK, 16",
        descOnPic: "“A youth both a protester and a Chil in Mob volunteer. She keeps an eye out for children in the protests, believing that the country will be better if we believe in the power of people. ”",
        desc1: "Back in 2010 when I was 7, my mom took me to the protest at the center of Bangkok. This experience is still vivid to me. Together with the news, it helped me understand Thai politics more. My family encouraged me to explore my life beyond school. At protests, I get to see people from different generations, meet many people, and know what to do when surrounded by unfamiliar faces.\n\nLooking back, people from the previous generation already fought once for this issue. My parents' generation already did, too. This time it's my turn to go and fight for my parents and Thailand's future.",
        desc2: "At one protest, I found a kid around 7 years old crying near a medical tent. The kid got lost from his parents in the protest. Behind us, there was a barricade from the police. I held this kid and turned back. I saw that the police were pushing towards the protesters and were 4 - 5 meters away. I held the kid so he could not see the police or the water cannon truck. At that moment, the father found us, and soon after, the police used force against the protesters. I got hit with tear gas. However, I was lucky and quite proud that I protected the kid. Otherwise, he would have been injured during the crackdown.\n\nThis is violence that the government is forcing upon us: we cannot avoid it, but we can protect ourselves from it by leaving the scene. That's the point of Child in Protest's volunteer. I'd check if the people around me are safe and see whether they can evacuate from the area by themselves. If I see that they can't, that's when I'd step in to help. ",
        desc3: "I want to study law and maybe become a judge or a prosecutor, or a politician. If I were to become a politician, I would not repeat the wrongdoings of politicians I’ve seen since I was young. I want to be a new generation politician who moves the country to a future we all want to see—a politician who isn’t self-centered and listens to other people.\n\nI’m still hopeful of fighting within the system to make our country a better place, and I believe everyone can do it, too. Even if Thailand does fall apart, I still wouldn’t want to leave anyone behind. I want to help improve our country and turn it into a nation that’s not forgotten, unlike how it is today.  The idea of moving to another country is worrying me. What about my friends and family, who have fought alongside me? I wouldn’t want to leave them behind. I want to continue to support them. ",
    },
]

export const page6Content = [
    {
        key: "page6_content_1",
        type: "Safety Equipments",
        dream: "I went to a protest with my dad at Kasetsart University to fight against unfair wages and poorly managed financial relief schemes. I hope one day I can feel safe in the demonstrations.\n\nI want to work as a bartender when I grow up, but I feel hopeless when I look at Thailand. I don't know if I will be living in this country.",
        name: "Pang, 15",
        support: "Child In Protest Team provides safety equipment, including vests, respirators, helmets, and first-aid kits to protect the safety of all children in protests."
    },
    {
        key: "page6_content_2",
        type: "Listening Volunteers",
        dream: "Everyone deserves the right to express their political opinions. Nobody should be arrested just for going to the protests. I don't want to see people die because of the government's failure.\n\nWhen I grow up, I want to be a nurse, living in a better-developed Thailand with a true democracy.",
        name: "New, 14",
        support: "These volunteers listen and give advice to children and youths without judgment and create a safe space for discussion. If a child is prosecuted for simply exercising their right to assembly and other fundamental human rights."
    },
    {
        key: "page6_content_3",
        type: "Public youth forum: “Children Consultation: Child's Rights to Safe Participation in Public Space”",
        dream: "I have been to protests before and have signed petitions against military dictatorship, including calling to revise the constitution. I express my political views on my social media such as Facebook, IG, and Twitter. Even though I want to fix the problems of social disparity in Thailand and see our country develop, I don't think I will be staying in this country in the future.",
        name: "Somcheng, 15",
        support: "The public youth forum provided a space for Thai youths to express opinions on their rights to safe participation in public areas. The meeting also collected feedback and suggestions for all stakeholders. This is because youth voice matters and needs to be listened to by adults in our society."
    },
    {
        key: "page6_content_4",
        type: "Speak for Children",
        dream: "I tied my hair with white ribbons and showed the Hunger Games three-fingered salute during the morning assembly because I could not stand the existing government's rule and their abuse of power. I dream of becoming a gamer living in a well-governed country with true democracy. But I think I will have to go and live in another country.",
        name: "Chompoo, 15",
        support: "This campaign aims to raise awareness about children's voices that have been ignored and communicate that society needs to end all forms of violence against children who exercise their freedom of expression."
    },
    {
        key: "page6_content_5",
        type: "Wristbands for youth in protesting sites",
        dream: "I attended a protest to express my opinions on the government. When I become an adult, I hope to see our country prosper with a strong economy and the right to express our political views freely.",
        name: "O-shi, 14",
        support: "A symbol to help identify children under the age of 18 in the protests. This will make it easier for adults and the media to refrain from taking their photographs and look out for any sexual harassment or violence targeted at them."
    },
    {
        key: "page6_content_6",
        type: "“Silent Voices Matter” Exhibition",
        dream: "I've never been to a protest, but I share my opinions and news related to the police's violence.\nI want to be an adult who is empathetic to others' problems.\nI dream that we would be able to share our views without violating the law freely.",
        name: "Tong, 14",
        support: "An exhibition from 10 young Thai artists that showcases the pain of Thai children growing up in an authoritarian society, where adults rarely listen to children's voices."
    },
    {
        key: "page6_content_7",
        type: "Amnesty Club House: a safe space to share gender discrimination stories and how to tackle it.",
        dream: "I dream that Thai society will be free from gender discrimination and no more LGBTQIA+ misrepresentation in the media. I want to live in a society that doesn't discriminate against any race or societal status.",
        name: "Best, 15",
        support: "In collaboration with insKru and Child in Protest, Amnesty International Thailand opened an online space on Amnesty Clubhouse to invite Thai youths and adults to discuss issues of gender discrimination in schools, media and society, and how to tackle them."
    },
    {
        key: "page6_content_8",
        type: "Online Quiz - Who are you in a social movement?",
        dream: "After graduation, I want to be a computer programmer living a quiet life. Maybe sometime in the future, Thailand will have economic stability. When that time comes, I hope I can live my life peacefully, without all today's chaos and social problems.",
        name: "Aoy, 16",
        support: "A simple online quiz that encourages everyone to take part in the human rights movement. The results will identify where you are on the political spectrum and how it connects with your personality."
    },
    {
        key: "page6_content_9",
        type: "Know Your Rights and Claim Them",
        dream: "I've taken the three-finger salute action and tie my hair with white ribbons to school. I joined protests to drive change in the education system. I want Thai politics to be free of corruption. Someday, I dream that everyone in Thailand will have equal rights to express their opinions.",
        name: "Oon, 15",
        support: "Angelina Jolie and Amnesty International have joined forces to create a resource for children and young people to learn about their rights, which empowers teenagers to speak out against injustice."
    },
    {
        key: "page6_content_10",
        type: "Child in Protest Volunteers",
        dream: "I fight for the right and freedom of political expression, which has been taken from us by this so-called democracy. In the future, I hope that Thailand's economy and society will improve. I want to become a doctor who takes care of the people and contributes to the idea that everyone must have the right to a quality life regardless of status.",
        name: "Thitipon, 14",
        support: "A group of volunteers working to raise awareness, hand out wristbands for children in protest areas and working up to 3 - 12 hours each protest to create safe zones. These volunteers come from different parts of Bangkok and now expanding to other provinces."
    },
]

export const page6Path = {
    amnestyLogo: '/asset/dream/Amnesty-support-heading.png',
    amnestyLogoNew: '/asset/dream/Amnesty-nosupport-heading.png',
    postItBlack: '/asset/dream/post-it-black.png',
    postItYellow: '/asset/dream/post-it-yellow.png',
    postItBlackLong: '/asset/dream/post-it-black-long.png',
    postItYellowLong: '/asset/dream/post-it-yellow-long.png'
}

export const page4Content = {
    ihearMusic: "/asset/iseeihearifeel/ihear/music.mp3",
    isee: [
        {
            topic: "The 1st Day of School",
            detail: "On December 1st, 2020, Thai students - primarily those in secondary education - protested for their right to express themselves through dress beyond the regulations of the school uniform. Students who wore casual clothes to school were prohibited from entering the school. At certain schools, parents were called in to talk about their children's behaviors.",
            type: "image",
            media: "/asset/iseeihearifeel/isee/1.JPG"
        },
        {
            topic: "The Tipping Point",
            detail: "When the police started using water cannons,\nit escalated the situation and ignited the movement across the country.",
            type: "image",
            media: "/asset/iseeihearifeel/isee/2.jpeg"
        },
        {
            topic: "Don't stop walking - Sopanat Somkhanngoen",
            detail: "A peaceful gathering of protesters, showing a solid conviction towards creating change.",
            type: "video",
            media: "/asset/iseeihearifeel/isee/3.mp4#t=0.1"
        },
        {
            topic: "Down with dictatorship, long live the people",
            detail: "This is the mission, the dream, and the change that protesters are seeking.This is the mission, the dream, and the change that protesters are seeking.",
            type: "video",
            media: "/asset/iseeihearifeel/isee/4.mov#t=0.1"
        },
    ],
    ihear: [
        {
            topic: "Cypherlogy MVP : MILLI | RAP IS NOW",
            detail: "A hip-hop song from MILLI, a young female artist, calling out against the government that has stripped rights and freedom from Thai people.",
            type: "video/youtube",
            media: "https://www.youtube.com/embed/fbX1BYMOP1U"
        },
        {
            topic: "‘Hunger Games’ Salute",
            detail: "The three-finger salute was adopted from the protagonist Katniss Everdeen of The Hunger Games movie franchise. It is an act of resistance against the military government, which is now widely used as a symbol by all generations to call for democracy.",
            type: "video/youtube",
            media: "https://www.youtube.com/embed/7KSNGaph4Ko"
        },
        {
            topic: "Hamtaro Protest",
            detail: "The Japanese animation Hamtaro inspired this creative protest. Youths assembled to run in a circle while singing the Hamtaro theme song. The lyrics were adapted to call for the government to be ousted.",
            type: "video/youtube",
            media: "https://www.youtube.com/embed/c0-ATHlc5R8"
        },
        {
            topic: "Harry Potter Protest",
            detail: "The generations who grew up reading Harry Potter got together to cast the “Expecto Patronum” spell. The symbolic action was to fight social oppression and protecting the democratic system where the authority has stripped human rights.",
            type: "video/youtube",
            media: "https://www.youtube.com/embed/bodUnuN-o1E"
        },
    ],
    ifeel: [
        {
            topic: "Unaccepted Bow",
            detail: "On October 16th, 2020, a protestor handed a white bow to the police to symbolize peace. Moments later, the police arrested him on the other end of the street.",
            type: "video",
            media: "/asset/iseeihearifeel/ifeel/1.mp4#t=0.1"
        },
        {
            topic: "The Grounded Arrest",
            detail: "Two young children were forced to the ground and arrested near the Din Daeng intersection on August 22nd, 2021.",
            type: "image",
            media: "/asset/iseeihearifeel/ifeel/2.jpeg"
        },
        {
            topic: "Beat It",
            detail: "On September 6th, 2021, a group of fully armed police on a mission to end the night's protest as quickly as possible charged protesters on motorcycles and struck them off their bikes.",
            type: "video",
            media: "/asset/iseeihearifeel/ifeel/3.mp4#t=0.1"
        },
        {
            topic: "Firing Season",
            detail: "On August 20th, 2021, a video showed the police pointing and firing rubber bullets at protesters at close range. The Deputy Chief of the Metropolitan Police Bureau stated that it may have been an act of self-defense.",
            type: "video",
            media: "/asset/iseeihearifeel/ifeel/4.mp4#t=0.1"
        },
    ],
}

// export const weblink = "https://childinmob.amnesty.co.th/"
export const weblink = "https://childinmob.amnesty.or.th/"
export const donationlink = "https://www.amnesty.or.th/en/special-page/child-mob"