import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import WheelController from '../../WheelController';
import { useDispatch, useSelector } from 'react-redux';
import ScrollSign from '../../ScrollSign';
import { page6Content, page6Path } from '../../../utils/asset';
import { useMediaQuery } from '../../../utils/customHook';
import { useSwipeable } from "react-swipeable";

// random 0 to max-1
const randomNum = (max) => Math.floor(Math.random() * max);

const Page6 = () => {
    const isSmallEnough = useMediaQuery('(max-width: 640px)');
    const dispatch = useDispatch();
    const onWheelFuncDown = () => {
        dispatch({ type: "SET_PAGE_ACTION", payload: { page: 7 } })
    }

    const scrollable = useSelector(state => state.componentReducer.scrollable)

    const onWheelFuncUp = () => {
        dispatch({ type: "SET_PAGE_ACTION", payload: { page: 5 } })
    }
    useEffect(() => {
        setRandomCard(page6Content[randomNum(10)])
    }, [])
    const [cardState, setCardState] = useState(true)
    const [randomCard, setRandomCard] = useState(null);

    const handlers = useSwipeable({
        onSwipedLeft: ()=>setCardState(!cardState),
        onSwipedRight: ()=>setCardState(!cardState)
    });

    const handlersText = useSwipeable({
        onSwipedUp: (e)=>e.event.cancelBubble = true,
        onSwipedDown: (e)=>e.event.cancelBubble = true
    })

    const handlersText2 = useSwipeable({
        onSwipedUp: (e)=>e.event.cancelBubble = true,
        onSwipedDown: (e)=>e.event.cancelBubble = true
    })

    const backCardClass = "pt-10 pb-10 px-10 sm:pt-20 sm:pb-28 sm:px-24 cursor-pointer"
    const backCardStyle = isSmallEnough ?
        { left: '20%', top: '14%', width: '85vw', height: '106.5vw', maxHeight: '380px' }
        :
        { left: '20%', top: '11%', width: '42vw', height: '70vh' }
    const backAnimate = isSmallEnough ?
        {
            scale: [1, 0.87, 0.75],
            left: ['20%', '10%', '25%'],
            z: [50, 20, 0],
            transition: {
                duration: 0.5
            }
        }
        : {
            scale: [1, 0.87, 0.75],
            left: ['20%', '10%', '42%'],
            z: [50, 20, 0],
            transition: {
                duration: 0.5
            }
        }

    const frontCardClass = "pt-10 pb-14 px-10 sm:pt-20 sm:pb-28 sm:px-24";
    const frontCardStyle = isSmallEnough ?
        { left: '42%', top: '14%', width: '85vw', height: '106.5vw', maxHeight: '380px' }
        :
        { left: '42%', top: '11%', width: '42vw', height: '70vh' }
    const frontAnimate = isSmallEnough ?
        {
            scale: [0.75, 0.87, 1],
            left: ['42%', '52%', '0%'],
            z: [0, 20, 50],
            transition: {
                duration: 0.5
            }
        }
        : {
            scale: [0.75, 0.87, 1],
            left: ['42%', '52%', '20%'],
            z: [0, 20, 50],
            transition: {
                duration: 0.5
            }
        }

    const stopBack = (e) => {
        if (!cardState) {
            e.stopPropagation();
        }
    }
    const stopFront = (e) => {
        if (cardState) {
            e.stopPropagation();
        }
    }

    return (
        <WheelController  onWheelFuncDown={onWheelFuncDown} onWheelFuncUp={onWheelFuncUp} style={{ backgroundImage: 'url(/asset/dream/bg.jpeg)' }} className="h-screen bg-cover bg-no-repeat relative  overflow-x-hidden">
            <div style={{transformStyle: 'preserve-3d'}} {...handlers}  className="w-full h-full">
            {randomCard &&
                <>
                    <motion.div
                        style={{ ...cardState ? backCardStyle : frontCardStyle, backgroundImage: `url(${isSmallEnough ? page6Path.postItYellowLong : page6Path.postItYellow})` }}
                        animate={cardState ? backAnimate : frontAnimate}
                        className={`absolute bg-stretchy  bg-center bg-no-repeat ${cardState ? backCardClass : frontCardClass}`}
                        onClick={() => setCardState(false)}
                    >
                        <div className="flex flex-col h-full">
                            <div className="flex items-center justify-end">
                                <img className="w-1/2" src={page6Path.amnestyLogoNew} />
                            </div>
                            <div  {...handlersText2} className={`h-full ${cardState ? "overflow-hidden" : "overflow-auto"} scrollbar-amnesty-inverse overflow-x-hidden`}>
                                <p onWheel={stopBack} className="z-40 text-black text-base sm:text-lg 2xl:text-2xl whitespace-pre-line">
                                    {randomCard.type}
                                    <br />
                                    <br />
                                    {randomCard.support}
                                </p>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        style={{ ...cardState ? frontCardStyle : backCardStyle, backgroundImage: `url(${isSmallEnough ? page6Path.postItBlackLong : page6Path.postItBlack})` }}
                        animate={cardState ? frontAnimate : backAnimate}
                        className={`absolute bg-stretchy bg-center bg-no-repeat ${cardState ? frontCardClass : backCardClass}`}
                        onClick={() => setCardState(true)}
                    >
                        <div className="flex flex-col h-full">
                            <div className="flex mb-8 items-center">
                                <h1 className="text-yellow-amnesty font-bold text-2xl sm:text-4xl 2xl:text-5xl ">OUR DREAMS</h1>
                            </div>
                            <div {...handlersText} className={`h-full scrollbar-amnesty ${cardState ? "overflow-auto" : "overflow-hidden"} overflow-x-hidden`}>
                                <p onWheel={stopFront} className="text-gray-400 text-base sm:text-lg 2xl:text-2xl whitespace-pre-line">
                                    {randomCard.dream}
                                </p>
                            </div>
                        </div>
                        <h4 className="absolute text-gray-400 bottom-1/8 right-1/10 text-lg font-bold">
                            {randomCard.name}
                        </h4>
                    </motion.div>
                    <div style={{ transform: "translateX(-50%) translateZ(100px)",maxWidth:'160px' }} className=" absolute flex justify-center items-center cursor-pointer w-1/4 h-10  bg-yellow-amnesty bottom-1/5 sm:bottom-1/8 left-1/2" onClick={() => setRandomCard(page6Content[randomNum(10)])}>
                        <p className="block font-bold">Random</p>
                    </div>
                    <motion.div initial={{ y: 25, x: '-50%', opacity: 0 }} animate={{ y: 5, x: '-50%', opacity: 1 }} transition={{ duration: 0.7 }} className="absolute bottom-1/7 sm:bottom-6 left-1/2">
                        <ScrollSign scroll={false} onClick={onWheelFuncDown}/>
                    </motion.div>
                </>
            }
            </div>
        </WheelController>
    )
}

export default Page6;