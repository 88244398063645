import { FaFacebookF, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { IoCodeSharp } from "react-icons/io5";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { weblink } from "../../../utils/asset";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ShareSocial = ({className=""}) => {
    return (
        <div className={className}>
            <h4 className="inline text-white font-bold align-middle mx-1">
                Share
            </h4>
            {/* 
                        hover:bg-blue-facebook
                        hover:bg-blue-twitter
                        hover:bg-green-whatsapp
                        hover:bg-yellow-amnesty hover:text-black
                        */}
            <FacebookShareButton url={weblink}>
                <button className="text-white text-2xl border-white border-2 rounded-full p-2 mx-1 align-middle hover:text-yellow-amnesty hover:border-yellow-amnesty">
                    <FaFacebookF />
                </button>
            </FacebookShareButton>
            <TwitterShareButton url={weblink}>
                <button className="text-white text-2xl border-white border-2 rounded-full p-2 mx-1 align-middle hover:text-yellow-amnesty hover:border-yellow-amnesty">
                    <FaTwitter />
                </button>
            </TwitterShareButton>
            <WhatsappShareButton url={weblink}>
                <button className="text-white text-2xl border-white border-2 rounded-full p-2 mx-1 align-middle hover:text-yellow-amnesty hover:border-yellow-amnesty">
                    <FaWhatsapp />
                </button>
            </WhatsappShareButton>
            <CopyToClipboard
                text={weblink}
                onCopy={() => alert("Copy to clipboard")}
            >
                <button
                    className="text-white text-2xl border-white border-2 rounded-full p-2 mx-1 align-middle hover:text-yellow-amnesty hover:border-yellow-amnesty"
                // onClick={() => { navigator.clipboard.writeText(weblink); alert("copy link to clipboard!") }}
                >
                    <IoCodeSharp />
                </button>
            </CopyToClipboard>
        </div>
    )
}

export default ShareSocial;