import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';

const WheelController = (props) => {
    const dispatch = useDispatch();
    const { customTimeWheelEnable = 2000, children, onWheelFuncDown = () => { }, onWheelFuncUp = () => { }, OnScrollFunc = () => { }, stateToReset = [], ...otherProps } = props;
    const [enableWheel, setEnableWheel] = useState(false);
    const scrollable = useSelector(state => state.componentReducer.scrollable)
    // top bottom scrolling

    const scrolling = useSelector(state=>state.componentReducer.scrolling)

    const setScrolling = (state)=>{
        dispatch({type:"UPDATE_SCROLLING_STATE",payload:{scrolling:state}})
    }

    const swipeUpEvent = ()=>{
        if(!scrollable || scrolling==="bottom"){
            onWheelFuncDown();
        }
    }

    const swipeDownEvent = ()=>{
        if(!scrollable || scrolling==="top"){
            onWheelFuncUp();
        }
    }

    const handlers = useSwipeable({
        onSwipedUp: swipeUpEvent,
        onSwipedDown: swipeDownEvent
    });

    useEffect(() => {
        dispatch({ type: "UPDATE_SCROLL", payload: { scrollable: false } })
        setTimeout(() => {
            setEnableWheel(true);
        }, customTimeWheelEnable)
        return () => {
        }
    }, [])

    const wheelcon = useRef();

    const wheelcount = useRef(0);
    const timerId = useRef(null);
    const wheelState = useRef(true);

    const onScrollTrack = (e) => {
        // delay after wheel controller mount
        if (!enableWheel) {
            return null;
        }
        // wheel count up or down
        const wDelta = e.nativeEvent.wheelDelta < 0 ? 'down' : 'up';
        if (wDelta === 'down') {
            wheelcount.current++;
        } else {
            wheelcount.current--;
        }
        // wheel count throttling for trackpad
        if (wDelta === 'down' && (wheelState.current || Math.abs(wheelcount.current) > 100)) {
            wheelcount.current = 0;
            // do something while scroll down here
            if (!scrollable || (scrolling === "bottom")) {
                onWheelFuncDown();
            }
        } else if (wDelta === 'up' && (wheelState.current || Math.abs(wheelcount.current) > 100)) {
            wheelcount.current = 0;
            // do something while scroll up here
            if (!scrollable || (scrolling === "top")) {
                onWheelFuncUp();
            }
        }
        // cancel throttling if it's mouse
        wheelState.current = false
        clearTimeout(timerId.current);
        timerId.current = setTimeout(() => {
            wheelcount.current = 0;
            wheelState.current = true;
        }, 200)
    }

    useEffect(() => {
        wheelcount.current = 0;
    }, stateToReset)


    const scrollingEvent = (e) => {
        if ((window.innerHeight + e.target.scrollTop) >= e.target.scrollHeight) {
            setScrolling("bottom")
        } else if (e.target.scrollTop === 0) {
            setScrolling("top")
        } else {
            setScrolling("scrolling")
        }
    }

    useEffect(()=>{
        console.log(scrolling)
    },[scrolling])
    return (
        <motion.div ref={wheelcon} {...handlers} onScroll={scrollingEvent} onWheel={onScrollTrack} {...otherProps} >
            {children}
        </motion.div>
    )
}

export default WheelController;