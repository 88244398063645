const RightContent = ({ topic, desc, picPath }) => {
    return (
        <div className="bg-black bg-opacity-60 px-8 py-10">
            {topic ? <h2 className="text-white font-bold text-3xl mb-6">{topic}</h2> : null}
            {desc ? <p className="text-white font-bold mb-6 whitespace-pre-line">{desc}</p> : null}
            <div className="flex justify-center">
                <img className="w-full" src={picPath} />
            </div>
        </div>
    )
}

export default RightContent;