import React from "react";
import ReactDOM from "react-dom";
import './index.css';
//redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import createSagaMiddleware from "redux-saga";
import App from "./App";
import mySaga from "./saga";
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware));
const ReduxApp = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

sagaMiddleware.run(mySaga);

ReactDOM.render(<ReduxApp />, document.getElementById("root"));
