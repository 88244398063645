import { useEffect, useRef } from "react";
import videojs from "video.js";
import 'videojs-youtube'

const VideoPlayer = ({ className = {}, src, type = "video/mp4", style,disablePlay }) => {

    const player = useRef();
    useEffect(() => {
        const vidplayer = new videojs(player.current, {
            aspectRatio: "16:9"
        });
    }, [src])
    return (
        <div key={src} style={style} className={className}>
            <div data-vjs-player >
                <video ref={player} playsInline controls preload="metadata" className={`${disablePlay ? "pointer-events-none" : ""} vjs-amnesty video-js vjs-big-play-centered`} >
                    <source src={src} type={type} />
                </video>
            </div>
        </div>
    )
}

export default VideoPlayer;