import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import H2 from './comp/H2';
import ProcessPoint from './comp/ProcessPoint';
import YesNoButton from './comp/YesNoButton';

const Quiz1 = () => {
    const dispatch = useDispatch();
    const page2State = useSelector(state => state.componentReducer.page2State);
    const answerFunc = (ans) => {
        dispatch({ type: "UPDATE_PAGE2_STATE", payload: { currentQuiz: page2State.currentQuiz, answer: ans } })
    }
    return (
        <div className="flex flex-col p-6 sm:p-20 justify-center h-full">
            <div className="pb-10 w-full lg:w-2/3">
                <H2>
                    <span className="">You can freely criticize your government without fear of danger.</span>
                </H2>
            </div>
            <div className="pb-16 sm:pb-32">
                <YesNoButton className="mr-2" textButton="Yes" onClick={() => answerFunc("YES")} />
                <YesNoButton className="ml-2" textButton="No" onClick={() => answerFunc("NO")} />
            </div>
            <div>
                <ProcessPoint />
            </div>
        </div>
    )
}

export default Quiz1;