import CardSlide from "./CardSlide";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "../../../../utils/customHook";
import { isMobile } from "react-device-detect";

const CardMobile = ({ contentArr }) => {
    const dispatch = useDispatch();
    const isSmallEnough = useMediaQuery('(max-width: 640px)');

    const onScrollEventWindiw = () => {
        if ((window.innerHeight+ window.scrollY) >= document.documentElement.scrollHeight) {
            dispatch({type:"UPDATE_SCROLLING_STATE",payload:{scrolling:"bottom"}})
        } else if (window.scrollY === 0) {
            dispatch({type:"UPDATE_SCROLLING_STATE",payload:{scrolling:"top"}})
        } else {
            dispatch({type:"UPDATE_SCROLLING_STATE",payload:{scrolling:"scrolling"}})
        }
    }

    useEffect(() => {
        if (isMobile || isSmallEnough ) {
            dispatch({ type: "UPDATE_SCROLL", payload: { scrollable: true } })
        }
        return ()=>{
            dispatch({ type: "UPDATE_SCROLL", payload: { scrollable: false } })
        }
    }, [isSmallEnough])

    useEffect(()=>{
        window.scrollTo(0, 0);
        window.addEventListener("scroll",onScrollEventWindiw)
        dispatch({type:"UPDATE_SCROLLING_STATE",payload:{scrolling:"top"}})
    },[])

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
            className="absolute flex flex-col justify-center h-auto"
        >
            {/* <div className="h-1/6"></div> */}
            <div className="h-auto mt-10 flex flex-col overflow-auto">
                <CardSlide
                    content={contentArr[0]}
                />
                <CardSlide
                    content={contentArr[1]}
                />
                <CardSlide
                    content={contentArr[2]}
                />
                <CardSlide
                    content={contentArr[3]}
                />
            </div>
        </motion.div>
    )
}

export default CardMobile;