import { motion, AnimatePresence } from 'framer-motion';
import { page2Path } from '../../../utils/asset';
import { useDispatch, useSelector } from 'react-redux';
import Skip from './comp/Skip';
import H2 from './comp/H2';
import H1 from './comp/H1';
import YesNoButton from './comp/YesNoButton';
import ProcessPoint from './comp/ProcessPoint';
import RightContent from './comp/RightContent';

const Result3 = () => {
    const dispatch = useDispatch();
    const page2State = useSelector(state => state.componentReducer.page2State);
    const nextFunc = () => {
        dispatch({ type: "UPDATE_PAGE2_SKIP", payload: { currentQuiz: 4, answer: null } })
    }

    const h1text = page2State.answer && page2State.answer === "YES" ? "You are correct." : ""
    const h2text = page2State.answer && page2State.answer === "YES" ? "Many protests in Thailand were ignited by young generations." : "These protests were ignited by the younger generation."
    const righttext = "The gathering of young people nowadays is similar to what happened in October 1976. Back then, the protest also led by university students."
    return (
        <>
            <div className="flex flex-col p-6 pt-28 sm:p-20 justify-center sm:h-full w-full sm:w-1/2">
                <div className="pb-0 sm:pb-10">
                    <H1>
                        {h1text}
                    </H1>
                    <H2>
                        {h2text}
                    </H2>
                </div>
                {/* hide if mobile */}
                <div className="hidden sm:block sm:pb-32">
                    <YesNoButton className="mr-2" textButton="Next" onClick={nextFunc} />
                </div>
                <div className="hidden sm:flex items-center">
                    <ProcessPoint />
                    <Skip />
                </div>
            </div>
            <div className="block sm:hidden">
                <RightContent
                    topic="Here’s Why,"
                    desc={righttext}
                    picPath={page2Path[2]}
                />
            </div>
            <div className="hidden sm:block absolute top-1/2 transform translate-x-1/2 -translate-y-1/2 right-1/4 w-1/3">
                <RightContent
                    topic="Here’s Why,"
                    desc={righttext}
                    picPath={page2Path[2]}
                />
            </div>
            {/* mobile added */}
            <div className="block sm:hidden py-16 pl-6">
                <YesNoButton className="mr-2" textButton="Next" onClick={nextFunc} />
            </div>
            <div className="flex sm:hidden items-center pl-6 pb-6">
                <ProcessPoint point={3} />
                <Skip />
            </div>
        </>
    )
}

export default Result3;