import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { page1Path } from '../../utils/asset';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ScrollSign from '../ScrollSign';
import WheelController from '../WheelController';

const picnum = page1Path.length;
const magicnum = 5;

const Page1 = () => {
    const dispatch = useDispatch();

    const [picState, setPicState] = useState(0);

    useEffect(() => {
        let looper;
        if (picState <= 4) {
            looper = setInterval(() => {
                setPicState(picState + 1);
            }, 1000);
        }
        return () => clearInterval(looper)
    }, [picState])

    const onWheelFuncDown = () => {
        if (picState >= picnum - 1) {
            dispatch({ type: "SET_PAGE_ACTION", payload: { page: 2 } })
        }
        if (picState > 4) {
            setPicState(picState + 1);
        }
    }
    const onWheelFuncUp = () => {
        // if (picState > 5) {
        //     setPicState(picState - 1);
        // }
    }

    const OnScrollFunc = () => {
        if (picState >= picnum - 1) {
            dispatch({ type: "SET_PAGE_ACTION", payload: { page: 2 } })
        }
        if (picState > 4) {
            setPicState(picState + 1);
        }
    }

    const dropIn = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.5
            }
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5
            }
        },
        exit: {
            opacity: 1,
            transition: {
                duration: 1.5
            }
        },
        slideHidden: {
            x: '100vw',
            transition: {
                duration: 0.5
            }
        },
        slideVisible: {
            x: 0,
            transition: {
                delay: 0.5,
                duration: 0.5
            }
        },
        slideExit: {
            x: 0,
            transition: {
                duration: 0.5
            }
        },
    }

    const timerVariants = {
        hidden: {
            width: 0
        },
        visible: {
            width: '100%',
            transition: {
                ease: 'linear',
                duration: 5.5
            }
        },
    }

    return (
        <WheelController onWheelFuncDown={onWheelFuncDown} onWheelFuncUp={onWheelFuncUp} OnScrollFunc={OnScrollFunc} stateToReset={[picState]}>
            <AnimatePresence initial={false} >
                <motion.div
                    key={`page1_img${picState}`}
                    style={{ backgroundImage: `url(${page1Path[picState]})` }}
                    className="absolute top-0 h-full w-full bg-cover bg-center bg-no-repeat z-0 " variants={dropIn} initial={picState !== 6 ? "hidden" : "slideHidden"}
                    animate={picState !== 6 ? "visible" : "slideVisible"}
                    exit={picState !== 6 ? "exit" : "slideExit"}
                >
                    {/* <button onClick={() => { dispatch({ type: "SET_PAGE_ACTION", payload: { page: 2 } }) }}>to page 2</button> */}
                    {picState < 6 ?
                        <motion.h1
                            key="h1_page1_smile"
                            initial={false}
                            exit={{ opacity: 0, transition: { duration: 0.5 } }}
                            className="absolute text-white top-1/6 sm:top-1/5 left-1/2 transform -translate-x-1/2 w-full font-bold filter text-shadow-page1 text-left sm:text-center px-10 sm:px-0"
                        >
                            <span className="hidden sm:inline text-8xl">THAILAND</span><br /><span className="hidden sm:inline text-6xl">LAND OF SMILE</span>
                            <span className="sm:hidden text-5xl">THAILAND<br/>LAND OF SMILE</span>
                        </motion.h1>
                        :
                        <motion.h1
                            key="h1_page1_really"
                            initial={{ opacity: picState === 6 ? 0 : 1 }}
                            animate={{ opacity: 1, transition: { delay: 1, duration: 0.5 } }}
                            exit={{ opacity: 1 }}
                            className="text-white text-6xl absolute top-1/5 left-1/2 transform -translate-x-1/2 w-full font-bold filter text-shadow-page1 text-left px-10 sm:px-0 sm:text-center"
                        >
                            <span className="hidden sm:inline">DO WE REALLY SMILE?</span>
                            <span className="inline sm:hidden">DO WE<br/>REALLY<br/>SMILE?</span>
                        </motion.h1>
                    }
                </motion.div>
                <AnimatePresence>
                    {picState < 6 ?
                        <motion.div
                            exit={{ opacity: 0, transition: { duration: 1 } }}
                            key="progress_bar_page1"
                            className="hidden overflow-hidden h-3 text-xs sm:flex bg-black absolute w-full left-1/2 transform -translate-x-1/2 bottom-0 border-yellow-amnesty border-2"
                        >
                            <motion.div
                                variants={timerVariants}
                                initial="hidden"
                                animate="visible"
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white bg-yellow-amnesty border-yellow-amnesty justify-center">
                            </motion.div>
                        </motion.div> :
                        null
                    }
                    {
                        picState >= 5 ?
                            <motion.div initial={{ y: 25, x: '-50%', opacity: 0 }} animate={{ y: 0, x: '-50%', opacity: 1 }} transition={{ duration: 0.7 }} className="absolute bottom-1/6 sm:bottom-10 left-1/2">
                                <ScrollSign onClick={onWheelFuncDown}/>
                            </motion.div> : null
                    }
                </AnimatePresence>
            </AnimatePresence>
        </WheelController>
    )
}

export default Page1;