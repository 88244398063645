import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import WheelController from '../../WheelController';
import Modal from 'react-modal';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos, MdOutlineClose } from 'react-icons/md'
import ProcessPoint from '../page2/comp/ProcessPoint';
import { page5Content } from '../../../utils/asset';
import { fisherYatesShuffle } from '../../../utils/function';
import PositionPoint from './PositionPoint';
import Page5Mobile from './Page5Mobile';
import Page5Desktop from './Page5Desktop';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.75)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0,0,0,0)',
        border: 0,
        borderRadius: 0,
        padding: 0
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const Page5 = () => {
    const dispatch = useDispatch();
    const onWheelFuncDown = () => {
        if (!modalIsOpen) {
            dispatch({ type: "SET_PAGE_ACTION", payload: { page: 6 } })
        }
    }

    const onWheelFuncUp = () => {
        dispatch({ type: "SET_PAGE_ACTION", payload: { page: 4 } })
    }

    const [modalIsOpen, setIsOpen] = useState(false);

    const [selectPage5Content, setSelectPage5Content] = useState(0);

    const [selectedModal, setSelectedModal] = useState(0);

    const [randomContent, setRandomContent] = useState([]);

    useEffect(() => {
        const shuffledPage5 = fisherYatesShuffle(page5Content);
        setRandomContent(shuffledPage5.slice(0, 3))
        // if (isMobile) {
        dispatch({ type: "UPDATE_SCROLL", payload: { scrollable: true } })
        // }
        return () => {
            dispatch({ type: "UPDATE_SCROLL", payload: { scrollable: false } })
        }
    }, [])

    function openModal(modalnum) {
        setSelectPage5Content(randomContent[modalnum])
        setSelectedModal(0);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const forwardModal = () => {
        if (selectedModal === 2) {
            setSelectedModal(0)
        } else {
            setSelectedModal(selectedModal + 1)
        }
    }

    const backwardModal = () => {
        if (selectedModal === 0) {
            setSelectedModal(2)
        } else {
            setSelectedModal(selectedModal - 1)
        }
    }

    if (randomContent.length === 0) {
        return null;
    }

    return (
        <WheelController onWheelFuncDown={onWheelFuncDown} onWheelFuncUp={onWheelFuncUp} className="h-screen bg-black overflow-auto">
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                closeTimeoutMS={300}
                onRequestClose={closeModal}
                // overlayClassName="fixed bg-black opacity-75 top-0 left-0 right-0 bottom-0"
                // className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div onWheel={e => e.stopPropagation()} className="bg-black border border-yellow-amnesty p-2 sm:p-6 md:p-10 lg:p-14 xl:px-20 xl:py-12 w-screen sm:w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-5xl overflow-hidden ">
                    <MdOutlineClose
                        className="absolute top-5 right-5 text-yellow-amnesty text-3xl border border-yellow-amnesty rounded-full hover:text-white hover:border-white cursor-pointer w-10 h-10 p-1"
                        onClick={closeModal}
                    />
                    <MdOutlineArrowBackIos
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-4xl cursor-pointer hover:text-yellow-amnesty"
                        onClick={backwardModal}
                    />
                    <MdOutlineArrowForwardIos
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-4xl cursor-pointer hover:text-yellow-amnesty"
                        onClick={forwardModal}
                    />
                    <div className="overflow-hidden px-10 py-0 sm:py-8">
                        <AnimatePresence exitBeforeEnter>
                            {/* <RenderModalContent /> */}
                            {selectedModal === 0 &&
                                <motion.div key={selectPage5Content.key + "1"} initial={{ x: '100vw' }} animate={{ x: 0 }} exit={{ x: '-100vw' }} transition={{ type: "tween", duration: 0.5 }}>
                                    <h1 className="text-white font-bold text-5xl mb-6">My Memories</h1>
                                    <p className="max-h-30vh scrollbar-amnesty overflow-auto text-white text-lg break-words whitespace-pre-line">{selectPage5Content.desc1}</p>
                                </motion.div>
                            } {selectedModal === 1 &&
                                <motion.div key={selectPage5Content.key + "2"} initial={{ x: '100vw' }} animate={{ x: 0 }} exit={{ x: '-100vw' }} transition={{ type: "tween", duration: 0.5 }}>
                                    <h1 className="text-white font-bold text-5xl mb-6">My Present</h1>
                                    <p className="max-h-30vh scrollbar-amnesty overflow-auto text-white text-lg break-words whitespace-pre-line">{selectPage5Content.desc2}</p>
                                </motion.div>
                            }{
                                selectedModal === 2 &&
                                <motion.div key={selectPage5Content.key + "3"} initial={{ x: '100vw' }} animate={{ x: 0 }} exit={{ x: '-100vw' }} transition={{ type: "tween", duration: 0.5 }}>
                                    <h1 className="text-white font-bold text-5xl mb-6">My Wish</h1>
                                    <p className="max-h-30vh scrollbar-amnesty overflow-auto text-white text-lg break-words whitespace-pre-line">{selectPage5Content.desc3}</p>
                                </motion.div>
                            }
                        </AnimatePresence>
                    </div>
                </div>
                <div className="text-center mt-5">
                    <PositionPoint point={selectedModal} />
                </div>
            </Modal>
            <Page5Desktop randomContent={randomContent} openModal={openModal} />
            <Page5Mobile randomContent={randomContent} openModal={openModal} />
        </WheelController>
    )
}

export default Page5;