import { useState } from "react";
import VideoPlayer from "../../../VideoPlayer";

const CardSlide = ({ content }) => {
    const [cardState, setCardState] = useState(false);
    const [textCardState, setTextState] = useState(false);
    const toggleCard = () => {
        setCardState(!cardState);
        if(!cardState){
            setTextState(!cardState)
        }else{
            setTimeout(()=>{
                setTextState(!cardState)
            },700)
        }
    }
    return (
        <div
            className={`p-3 m-3 cursor-pointer border-yellow-amnesty border bg-black bg-opacity-70 h-auto overflow-hidden`}
            onClick={toggleCard}
        >
            {/* <img src={content.media} /> */}
            {
                content.type === "video/youtube" &&
                <VideoPlayer src={content.media} type="video/youtube" />
            }
            {
                content.type === "video" &&
                <VideoPlayer src={content.media} />
            }
            {
                content.type === "image" &&
                <img src={content.media} />
            }
            <h3 className="text-white font-bold text-lg line-clamp-2">{content.topic}</h3>
            <p className={`transition-max-height duration-700 ease-in-out text-white text-sm ${textCardState ? "" : "line-clamp-3"} ${cardState?"max-h-screen":"max-h-16"} overflow-hidden`}>
                {content.detail}
            </p>
        </div>
    )
}

export default CardSlide;